import * as config from '@arch-log/webapp.modules/config/config'

import { createRouteWithDefaultProps } from '../utils/RouteHelper'

/**
 * Generate CreateRoute function with DefaultProps
 */
const createRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.plugin.baseUrl'),
}))

/**
 */
export const toProjects = createRoute(() => '/')

/**
 */
export const toProject = createRoute(
  ({ project }) => `/${project}/`
)

export const toTop = toProjects
