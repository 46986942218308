import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import { Context as LanguageContext } from '@arch-log/webapp.modules/i18n/components/Languages/Context'
import * as Routes from '@arch-log/webapp.shared/Routes'

import * as Layout from './Layout'
import * as Styled from './Styled'
import * as Form from './Form'

/**
 */
const DefaultOnSearch = (keyword, { language = 'ja' }) => {
  window.location.href = Routes.toProductSearch({
    condition: { keyword },
    language,
  })
}

/**
 *
 */
export const Default = withDefaultRenderComponent(
  ({
    onSearch = DefaultOnSearch,
    defaultKeyword,
    components: {
      Form: {
        Initializer = Form.Initializer,
        Inputs: { Keyword = Form.Keyword } = {},
        Submit = Form.Submit,
      } = {},
      Layout: { Container = Layout.Container } = {},
    } = {},
  }) => {
    const { language } = React.useContext(LanguageContext)

    return (
      <Initializer
        onSearch={(keyword) => onSearch(keyword, { language })}
        defaultKeyword={defaultKeyword}
      >
        <Container>
          <Keyword />
          <Submit>
            <Styled.Submit />
          </Submit>
        </Container>
      </Initializer>
    )
  },
)
