/**
 */
export class Config {
  /**
   */
  constructor({ ...params } = {}) {
    this.params = {
      ...params,
    }
  }

  /**
   */
  init = ({ ...params }) => {
    this.params = params
    return this
  }

  /**
   */
  get = (key, defaultValue = null) => {
    try {
      return key.split('.').reduce((z, x) => {
        if (z[x] === undefined) {
          throw `Umbiguous configuration Key ${x}.`
        }
        return z[x]
      }, this.params)
    } catch (e) {
      //console.info(e)
      return defaultValue
    }
  }

  /**
   */
  set = (key, value) => {
    this.params[key] = value
    return this
  }

  /**
   */
  all = () => {
    return this.params
  }
}

/**
 */
const instance = new Config()

/**
 */
export const getInstance = () => {
  return instance
}

/**
 */
export const get = (key, defaultValue = null) =>
  getInstance().get(key, defaultValue)

/**
 */
export const set = (key, value = null) => getInstance().get(key, value)

/**
 */
export const init = (params) => getInstance().init(params)

/**
 */
export const all = () => getInstance().all()
