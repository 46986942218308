import React from 'react'

import * as i18n from 'react-i18next'

export const withTranslation = i18n.withTranslation

/**
 */
export const withPageTranslation = (pagePrefix) => (Component) => {
  return withTranslation('pages')(({ t, ...props }) => {
    const pageT = (key) => t(`${pagePrefix}.${key}`)

    return <Component {...props} t={t} pageT={pageT} />
  })
}

/**
 * const withT = withCustomTranslation("module")(({t}) => {
 *  fieldLabelT: (field) => t(`fields.${field}.label`),
 * })(Component)
 */
export const withCustomTranslation = (ns) => (extras) => (Component) => {
  return withTranslation(ns)(({ t, ...props }) => {
    return <Component {...extras({ t, ...props })} t={t} {...props} />
  })
}

/**
 */
export const translated =
  (ns) =>
  (key, opts = {}) =>
    withTranslation(ns)(({ t, options = {} }) => (
      <>{t(key, { ...opts, ...options })}</>
    ))

export const useTranslation = i18n.useTranslation
