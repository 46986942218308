/**
 */
export const Missing = 'Missing'

/**
 */
export const ConditionalMismatch = 'ConditionMismatch'

/**
 */
export const ConditionalNotIncluded = 'ConditionalNotIncluded'

/**
 */
export const PatternMismatch = 'PatternMismatch'

/**
 */
export const DateMustBefore = 'DateMustBefore'

/**
 */
export const InvalidField = 'InvalidField'

/**
 */
export const InvalidMaxLength = 'InvalidMaxLength'

/**
 */
export const InvalidMinLength = 'InvalidMinLength'

/**
 */
export const InvalidLength = 'InvalidLength'

/**
 */
export const MissingLowerCase = 'MissingLowerCase'

/**
 */
export const MissingUpperCase = 'MissingUpperCase'

/**
 */
export const MissingNumeral = 'MissingNumeral'

/**
 */
export const MissingSymbol = 'MissingSymbol'
