import React from 'react'

import * as Errors from '@arch-log/webapp.lib/errors'
import * as ErrorComponents from '@arch-log/webapp.shared/Errors'

/**
 */
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    switch (error) {
      case Errors.NotAuthenticated:
      case Errors.Forbidden:
        return { error }
      default:
        return {}
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);

    switch (error) {
      case Errors.NotAuthenticated:
      case Errors.Forbidden:
        return
      default:
        throw error
    }
  }

  render() {
    const {
      components: {
        NotAuthenticated = ErrorComponents.NotAuthenticatedV2.Default,
        Forbidden = ErrorComponents.NoPermissionV2.Default,
      } = {},
    } = this.props

    if (this.state.error) {
      switch (this.state.error) {
        case Errors.NotAuthenticated:
          return <NotAuthenticated />
        case Errors.Forbidden:
          return <Forbidden />
        default:
          return <ErrorComponents.Default />
      }
    }

    return this.props.children
  }
}
