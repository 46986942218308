import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Logo = ({ children }) => (
  <div className={styles.Logo}>{children}</div>
)

/**
 */
export const Copyright = ({ children }) => (
  <div className={styles.Copyright}>{children}</div>
)
