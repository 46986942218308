import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Links from '@arch-log/webapp.shared/Links'
import * as Literals from './Literals'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'

/**
 */
export const Message = withDefaultRenderComponent(
  ({
    components: { Decorator = Styled.Message } = {},
    message = <Literals.Message />,
  }) => <Decorator>{message}</Decorator>,
)

/**
 */
export const SelectProducts = withDefaultRenderComponent(
  ({ message = <Literals.SelectProducts /> }) => (
    <Links.ProductSearch.ToTop className={styles.Link}>
      {message}
    </Links.ProductSearch.ToTop>
  ),
)
