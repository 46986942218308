/**
 */
const splitPathAndSearchParams = (to) => {
  const [path, params = null] = to.split('?')

  return [path, params ? new URLSearchParams(params) : new URLSearchParams()]
}

export class Router {
  /**
   */
  constructor() {
    this._defaultParams = new URLSearchParams()
  }

  /**
   */
  get defaultParams() {
    return this._defaultParams
  }

  /**
   */
  generatePath = (to, searchParams = null) => {
    const [path, params] = splitPathAndSearchParams(to)

    new URLSearchParams(searchParams).forEach((value, key) => {
      if ((value ?? null) !== null) {
        params.append(key, value)
      }
    })

    this.defaultParams.forEach((value, key) => {
      if ((value ?? null) !== null) {
        params.append(key, value)
      }
    })

    const paramString = params.toString()

    if (paramString.length > 0) {
      return [path, paramString].join('?')
    }

    return path
  }
}
