import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const DefaultError_Title = translated('common')(
  'Errors.DefaultError.Title',
)

/**
 */
export const DefaultError_Message = translated('common')(
  'Errors.DefaultError.Message',
)

/**
 */
export const PageNotFound_Title = translated('common')(
  'Errors.PageNotFound.Title',
)

/**
 */
export const PageNotFound_Message = translated('common')(
  'Errors.PageNotFound.Message',
)

/**
 */
export const ProjectNotFound_Title = translated('module.project')(
  'Errors.ProjectNotFound.Title',
)

/**
 */
export const ProjectNotFound_Message = translated('module.project')(
  'Errors.ProjectNotFound.Message',
)
