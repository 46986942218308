import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */
export const Message = withDefaultRenderComponent(
  ({
    components: { Decorator = Styled.Message } = {},
    message = <Literals.Message />,
  }) => <Decorator>{message}</Decorator>,
)
