import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const ProjectManagement = translated('common')('Menu.ProjectManagement')

/**
 */
export const AccountSetting = translated('common')('Menu.AccountSetting')

/**
 */
export const PluginDownload = translated('common')('Menu.PluginDownload')

/**
 */
export const Logout = translated('common')('Menu.Logout')
