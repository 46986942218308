import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    items = [
      <Containers.ToSetting key="setting" />,
      <Containers.ToPluginDownload key="plugin" />,
      <Containers.ChangeLanguage key="language" />,
      <Containers.SignOut key="signout" />,
    ],
    components: {
      Layout: {
        Wrapper = Layout.Wrapper,
        DropdownSection = Layout.Dropdown,
        Items = Layout.Items,
        Item = Layout.Item,
      } = {},
    } = {},
  }) => {
    const itemComponents = items.map((item, idx) => (
      <Item key={idx}>{item}</Item>
    ))

    return (
      <Containers.Initializer>
        <Wrapper>
          <Containers.Toggle />
          <Containers.DropMenu>
            <DropdownSection>
              <Items>{itemComponents}</Items>
            </DropdownSection>
          </Containers.DropMenu>
        </Wrapper>
      </Containers.Initializer>
    )
  },
)
