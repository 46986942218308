import i18next from "i18next"

import LanguageDetector from "i18next-browser-languagedetector"

/**
 * initialize i18n
 */
export const init = async ({
  fallbackLng = "ja",
  ns = ["common"],
  defaultNS = "common",
  languages = ["en", "ja"],
  language = null,
}) => {
  return i18next
    .use(LanguageDetector)
    .init({
      fallbackLng,
      ns,
      supportedLngs: languages,
      defaultNS,
      returnObjects: true,
      //debug: process.env.NODE_ENV === "development",
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      react: {
        wait: true,
      },
    })
    .then((ret) => i18next)
    .then((i18next) => {
      i18next.languages = languages
      return i18next
    })
    .then((i18next) => {
      if (language) {
        i18next.changeLanguage(language)
      }
      return i18next
    })
}

export { i18next }
