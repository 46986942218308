import React from 'react'

/**
 * Provide Context value
 *
 */
export const selectContextValue =
  (Context) =>
  (selector = (data) => data) => {
    return selector(React.useContext(Context))
  }

/**
 * Wrap component with Context.Provider if not yet wrapped
 */
export const withInitContext =
  (Context, contextValueLoader) => (Component) => (props) => {
    if (!Context) {
      throw new Error('Context is not specified')
    }
    const context = React.useContext(Context)

    if (!Component) {
      throw new Error('Component is not specified')
    }

    if (context) {
      return <Component {...props} />
    }

    return (
      <Context.Provider value={contextValueLoader()}>
        <Component {...props} />
      </Context.Provider>
    )
  }

/**
 */
export const withContextProps =
  (Context, propsSelector = (data) => data) =>
  (Component, defaultProps = {}) =>
  (props) => {
    if (!Context) {
      throw new Error('Context is not specified')
    }

    const context = React.useContext(Context)

    if (!context) {
      throw new Error(
        `Context.Provider is not mounted to render Component: ${Component.name}`,
      )
    }

    const contextProps = (() => {
      try {
        return propsSelector(context)
      } catch (err) {
        console.error('Failed to select value', Component.name, context)
        throw new Error('Failed to select value.')
      }
    })()

    if (!Component) {
      throw new Error('Component is not specified')
    }
    return <Component {...{ ...defaultProps, ...props, ...contextProps }} />
  }

/**
 * Wrapped Component that with specified Context value
 *
 * const Component = withContextValue(Context, context => context.id)(IdRenderer)
 */
export const withContextValue =
  (Context, valueSelector = (data) => data) =>
  (Component, defaultProps = {}) =>
  (props) => {
    if (!Context) {
      throw new Error('Context is not specified')
    }

    const context = React.useContext(Context)

    if (!context) {
      throw new Error(
        `Context.Provider is not mounted to render Component: ${Component.name}`,
      )
    }

    const value = (() => {
      try {
        return valueSelector(context)
      } catch (err) {
        console.error('Failed to select value', Component.name, context)
        throw new Error('Failed to select value.')
      }
    })()

    if (!Component) {
      throw new Error('Component is not specified')
    }
    return <Component {...{ ...defaultProps, ...props }} value={value} />
  }

export const createSelector = (data) => (selector) => {
  return selector(data)
}
