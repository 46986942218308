import * as config from '@arch-log/webapp.modules/config/config'

import { createRouteWithDefaultProps } from '../utils/RouteHelper'

/**
 * Generate CreateRoute function with DefaultProps
 */
const createRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.auth.baseUrl'),
}))

/**
 */
const createSearchParamsForRedirectUri = (redirectUri = null) => {
  const params = new URLSearchParams()

  if (!redirectUri && (typeof window !== 'undefined')) {
    params.append('redirect_uri', window.location)
  } else if(redirectUri) {
    params.append('redirect_uri', redirectUri)
  }

  return params
}

/**
 */
export const toLogin = createRoute(({ redirectUri = null, mode = null}) => {
  const params = createSearchParamsForRedirectUri(redirectUri)

  if(mode) {
    params.set('mode', mode)
  }

  return `/?${params.toString()}`
})

/**
 */
export const toLogout = createRoute(({ redirectUri = null }) => {
  const params = createSearchParamsForRedirectUri(redirectUri)

  return `/logout/?${params.toString()}`
})

export const toResetPassword = createRoute(() => {
  return '/resetPassword/'
})
