import React from 'react'

import * as styles from './style.module.scss'

const DefaultMessage = 'Loading...'

/**
 * Page Loading
 */
//export const Page = ({ children }) => (
//  <div className={ styles.Page_Container }>
//    <span className={styles.Page_Message}>{ children ? children : DefaultMessage }</span>
//  </div>
//)
//
export const Page = ({ children, className }) => (
  <div className={ `${ styles.Page_Container} ${className}` }>
    <div>
      <p>{children ? children : DefaultMessage}</p>
      <div className={styles.Loading}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
)
