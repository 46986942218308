import React from 'react'

/**
 * Rules to provide component to bind UI and Data/Actions
 */
export const withDefaultRenderComponent = (DefaultRenderer) =>
  function Base({ components = {}, ...props }) {
    if (components && typeof components === 'function') {
      const Renderer = components
      return <Renderer {...props} />
    }

    const { render: Renderer = DefaultRenderer, ...subComponents } =
      components ?? {}
    return <Renderer components={subComponents} {...props} />
  }
