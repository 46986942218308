import React from 'react'

import * as DefaultStyles from './default.module.scss'

/**
 * value = {
 *   src,
 *   alt,
 * }
 */
export const ImageRenderer = ({
  alt,
  value,
  src,
  children,
  className,
  styles = DefaultStyles,
  ...props
}) => {
  return (
    <img
      src={src ? src : value.src}
      className={className ?? styles.Image}
      alt={alt ? alt : value.alt}
      {...props}
    />
  )
}

/**
 * IE not support object-fit, so use div
 */
export const KeepRatioImageRenderer = ({ supportIE = false, ...props }) => {
  if (supportIE) {
    return <KeepRatioImageRendererForIe {...props} />
  }

  return <KeepRatioImageRendererDefault {...props} />
}

export const KeepRatioImageRendererDefault = ({
  alt,
  value,
  src,
  children,
  className,
  styles = DefaultStyles,
  ...props
}) => {
  return (
    <div {...props} className={className ?? styles.ImageKeepRatio_Container}>
      <img
        src={src ?? value.swrc}
        alt={alt}
        className={styles.ImageKeepRatio_Image}
      />
    </div>
  )
}

/**
 * IE not support object-fit, so use div
 */
export const KeepRatioImageRendererForIe = ({
  alt,
  value,
  src,
  children,
  className,
  styles = DefaultStyles,
  ...props
}) => {
  return (
    <div
      {...props}
      className={className ?? styles.ImageKeepRatioForIE_Container}
    >
      <div
        className={styles.ImageKeepRatioForIE_Inner}
        style={{
          backgroundImage: `url(${src ?? value.src})`,
          color: 'red',
        }}
      ></div>
    </div>
  )
}
