import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query MyProfileEntry($options: UserProfileEntry_Input_Options) {
    businessTypes: typeOfBusiness {
      key
      labels {
        ja
        en
      }
    }
    entry: myProfileEntry(options: $options) {
      id
      firstname
      lastname
      phoneticFirstname
      phoneticLastname
      businessInfo {
        isIndividual
        companyLabel
        divisionLabel
        jobTitle
        businessType
      }
    }
  }
`
