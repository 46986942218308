/**
 * Link aliases for global usage.
 */

import React from 'react'

import * as AddToProject from '@arch-log/webapp.shared/AppDefinitions/AddToProject/Links'
import * as Auth from '@arch-log/webapp.shared/AppDefinitions/Auth/Links'
import * as Board from '@arch-log/webapp.shared/AppDefinitions/Board/Links'
import * as Contact from '@arch-log/webapp.shared/AppDefinitions/Contact/Links'
import * as Faq from '@arch-log/webapp.shared/AppDefinitions/Faq/Links'
import * as HowTo from '@arch-log/webapp.shared/AppDefinitions/HowTo/Links'
import * as Plugin from '@arch-log/webapp.shared/AppDefinitions/Plugin/Links'
import * as PluginDownload from '@arch-log/webapp.shared/AppDefinitions/PluginDownload/Links'
import * as Product from '@arch-log/webapp.shared/AppDefinitions/Product/Links'
import * as ProductSearch from '@arch-log/webapp.shared/AppDefinitions/ProductSearch/Links'
import * as Project from '@arch-log/webapp.shared/AppDefinitions/Project/Links'
import * as Registration from '@arch-log/webapp.shared/AppDefinitions/Registration/Links'
import * as Requirements from '@arch-log/webapp.shared/AppDefinitions/Requirements/Links'
import * as Reservation from '@arch-log/webapp.shared/AppDefinitions/Reservation/Links'
import * as Setting from '@arch-log/webapp.shared/AppDefinitions/Setting/Links'
import * as Showcase from '@arch-log/webapp.shared/AppDefinitions/Showcase/Links'
import * as Static from '@arch-log/webapp.shared/AppDefinitions/Static/Links'
import * as BimRequest from '@arch-log/webapp.shared/AppDefinitions/BimRequest/Links'
import * as ProductRequestForm from '@arch-log/webapp.shared/AppDefinitions/ProductRequestForm/Links'
import * as Externals from './Externals'

/**
 *
 */
export const ToTop = Static.ToTop

/**
 *
 */
export const ToLogin = Auth.ToLogin

/**
 *
 */
export const ToLogout = Auth.ToLogout

/**
 *
 */
export const ToFaq = Faq.ToTop

/**
 *
 */
export const ToNews = Externals.ToNews

/**
 *
 */
export const ToMateria = Externals.ToMateria

/**
 *
 */
export const ToAbout = Static.ToAbout

/**
 *
 */
export const ToShowcase = Showcase.ToTop

/**
 *
 */
export const ToHowTo = Static.ToHowTo

/**
 *
 */
export const ToSystemRequirements = Requirements.ToTop

/**
 *
 */
export const ToContact = Contact.ToTop

/**
 *
 */
export const ToCampaigns = Static.ToCampaigns

/**
 *
 */
export const ToPublish = Static.ToPublish

/**
 *
 */
export const ToPrivateAccount = Static.ToPrivateAccount

/**
 *
 */
export const ToReservation = Reservation.ToTop

/**
 *
 */
export const ToReservationIntro = Static.ToOnDemand

/**
 *
 */
export const ToCompanyProfile = Externals.ToCompanyProfile

/**
 *
 */
export const ToTerms = Static.ToTerms

/**
 *
 */
export const ToCommercialTransactions = Static.ToCommercialTransactions

/**
 *
 */
export const ToPrivacyPolicy = Static.ToPrivacyPolicy

/**
 */
export const ToProduct = Product.ToDetail

/**
 */
export const ToProductSearch = ProductSearch.ToTop

/**
 */
export const ToAccountInfo = Setting.ToAccountInfo

/**
 */
export const ToPluginDownload = PluginDownload.ToTop

/**
 */
export const ToRegistration = Registration.ToTop

/**
 */
export const ToBimRequest = BimRequest.ToTop

/**
 */
export const ToProductRequestForm = ProductRequestForm.ToForm

/**
 */
export const Noop = ({ children }) => <>{children}</>
