/**
 * Application Based Links
 */
import React from 'react'

import { createLink } from '@arch-log/webapp.shared/Link'
import * as config from '@arch-log/webapp.modules/config/config'

import * as Routes from './Routes'

/**
 *
 */
const defaultProps = {
  baseUrl: config.get('apps.static.baseUrl'),
}

/**
 */
export const ToTop = createLink(Routes.toTop)

/**
 */
export const ToMerit = createLink(Routes.toMerit)

/**
 */
export const ToAbout = createLink(Routes.toAbout)

/**
 */
export const ToCampaigns = createLink(Routes.toCampaigns)

/**
 */
export const ToPublish = createLink(Routes.toPublish)

/**
 */
export const ToPrivateAccount = createLink(Routes.toPrivateAccount)

/**
 */
export const ToTerms = createLink(Routes.toTerms)

/**
 */
export const ToPrivacyPolicy = createLink(Routes.toPrivacyPolicy)

/**
 */
export const ToCommercialTransactions = createLink(Routes.toCommercialTransactions)

/**
 */
export const ToOnDemand = createLink(Routes.toOnDemand)

/**
 */
export const ToHowTo = createLink(Routes.toHowTo)
