import * as config from '@arch-log/webapp.modules/config/config'

import { createRouteWithDefaultProps } from '../utils/RouteHelper'

/**
 * Generate CreateRoute function with DefaultProps
 */
const createRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.static.baseUrl'),
}))

/**
 */
export const toTop = createRoute(() => '/')

/**
 */
export const toMerit = createRoute(() => '/merit/')

/**
 */
export const toAbout = createRoute(() => '/about/')

/**
 */
export const toCampaigns = createRoute(() => '/campaigns/')

/**
 */
export const toPublish = createRoute(() => '/publish/')

/**
 */
export const toPrivateAccount = createRoute(() => '/privateAccount/')

/**
 */
export const toTerms = createRoute(() => '/terms/')

/**
 */
export const toPrivacyPolicy = createRoute(() => '/privacyPolicy/')

/**
 */
export const toCommercialTransactions = createRoute(
  () => '/commercialTransaction/',
)

/**
 */
export const toOnDemand = createRoute(() => '/onDemand/')

/**
 */
export const toHowTo = createRoute(() => '/howto/')
