import React from "react"

//import { Context } from "./Context"
import { I18nextProvider, I18nContext } from "react-i18next"
//
///**
// */
//export const Provider = ({
//  i18next,
//  language,
//  defaultLanguage = "en",
//  languages,
//  children,
//}) => {
//  if (language !== i18next.language) {
//    i18next.changeLanguage(language)
//  }
//
//  return (
//    <Context.Provider
//      value={{
//        i18next,
//        language:
//          language !== "x-default"
//            ? language
//            : defaultLanguage,
//        languages,
//      }}
//    >
//      <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
//    </Context.Provider>
//  )
//}
/**
 */


const WrappedContent = ({ language, children }) => {
  const { i18n } = React.useContext(I18nContext)

  React.useEffect(() => {
    if (language !== i18n.language) {
      i18n.changeLanguage(language).then(() => {
        // language
      })
    }
  }, [language])

  return <>{children}</>
}

export const Provider = ({
  i18next,
  language,
  children,
}) => {
  return (
    <I18nextProvider i18n={i18next}>
      <WrappedContent language={language}>
        {children}
      </WrappedContent>
    </I18nextProvider>
  )
}
