import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Message = ({ children }) => {
  return <div className={styles.Message}>{children}</div>
}

