import * as Config from '@arch-log/webapp.lib/config'

const hostname =
  process.env.NEXT_PUBLIC_SITE_HOSTNAME || process.env.GATSBY_SITE_HOSTNAME
const protocol =
  process.env.NEXT_PUBLIC_SITE_PROTOCOL || process.env.GATSBY_SITE_PROTOCOL
const prefix =
  process.env.NEXT_PUBLIC_SITE_PREFIX || process.env.GATSBY_SITE_PREFIX || ''

const parseJsonFormat = (v) => (v ? JSON.parse(v) : null)

/**
 * INITIALIZE Configuration over apps
 */
export const init = () => {
  Config.init({
    google_analytics: {
      tracking_id:
        process.env.GATSBY_GA_TRACKING_ID ||
        process.env.NEXT_PUBLIC_GA_TRACKING_ID,
    },
    list_finder: {
      tracking_id:
        process.env.GATSBY_LIST_FINDER_TRACKING_ID ||
        process.env.NEXT_PUBLIC_LIST_FINDER_TRACKING_ID,
    },
    bim: {
      download: {
        baseUrl: process.env.GATSBY_BIM_DOWNLOAD_BASEURL,
      },
    },
    auth: {
      tokenProvider: {
        baseUrl:
          process.env.GATSBY_AUTH_TOKEN_PROVIDER_BASEURL ||
          process.env.NEXT_PUBLIC_AUTH_TOKEN_PROVIDER_BASEURL,
      },
      defaultRedirectUri:
        process.env.NEXT_PUBLIC_AUTH_DEFAULT_REDIRECT_URI ||
        process.env.GATSBY_AUTH_DEFAULT_REDIRECT_URI,
    },
    apps: {
      auth: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_AUTH_BASEURL ||
          process.env.GATSBY_APP_AUTH_BASEURL,
        loginUrl:
          process.env.NEXT_PUBLIC_APP_AUTH_LOGIN_URL ||
          process.env.GATSBY_APP_AUTH_LOGIN_URL,
      },
      default: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_DEFAULT_BASEURL ||
          process.env.GATSBY_APP_DEFAULT_BASEURL,
      },
      faq: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_FAQ_BASEURL ||
          process.env.GATSBY_APP_FAQ_BASEURL,
      },
      howto: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_HOWTO_BASEURL ||
          process.env.GATSBY_APP_HOWTO_BASEURL,
      },
      static: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_STATIC_BASEURL ||
          process.env.GATSBY_APP_STATIC_BASEURL,
      },
      plugin: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_PLUGIN_BASEURL ||
          process.env.GATSBY_APP_PLUGIN_BASEURL,
      },
      product: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_PRODUCT_BASEURL ||
          process.env.GATSBY_APP_PRODUCT_BASEURL,
      },
      project_product: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_PROJECT_PRODUCT_BASEURL ||
          process.env.GATSBY_APP_PROJECT_PRODUCT_BASEURL,
      },
      product_search: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_PRODUCT_SEARCH_BASEURL ||
          process.env.GATSBY_APP_PRODUCT_SEARCH_BASEURL,
        isExpandChildProductsEnabled: parseJsonFormat(
          process.env.GATSBY_APP_SEARCH_ENABLE_EXPAND_CHILD_PRODUCTS,
        ),
      },
      project: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_PROJECT_BASEURL ||
          process.env.GATSBY_APP_PROJECT_BASEURL,
      },
      registration: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_REGISTRATION_BASEURL ||
          process.env.GATSBY_APP_REGISTRATION_BASEURL,
      },
      requirements: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_REQUIREMENTS_BASEURL ||
          process.env.GATSBY_APP_REQUIREMENTS_BASEURL,
      },
      reservation: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_RESERVATION_BASEURL ||
          process.env.GATSBY_APP_RESERVATION_BASEURL,
      },
      showcase: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_SHOWCASE_BASEURL ||
          process.env.GATSBY_APP_SHOWCASE_BASEURL,
      },
      materia: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_MATERIA_BASEURL ||
          process.env.GATSBY_APP_MATERIA_BASEURL,
        postUrl:
          process.env.NEXT_PUBLIC_APP_MATERIA_POSTURL ||
          process.env.GATSBY_APP_MATERIA_POSTURL,
      },
      setting: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_SETTING_BASEURL ||
          process.env.GATSBY_APP_SETTING_BASEURL,
      },
      contact: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_CONTACT_BASEURL ||
          process.env.GATSBY_APP_CONTACT_BASEURL,
      },
      plugin_download: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_PLUGIN_DOWNLOAD_BASEURL ||
          process.env.GATSBY_APP_PLUGIN_DOWNLOAD_BASEURL,
      },
      board: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_BOARD_BASEURL ||
          process.env.GATSBY_APP_BOARD_BASEURL,
        imageBaseUrl:
          process.env.NEXT_PUBLIC_APP_BOARD_IMAGEBASEURL ||
          process.env.GATSBY_APP_BOARD_IMAGE_BASEURL,
      },
      product_compare: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_PRODUCT_COMPARE_BASEURL ||
          process.env.GATSBY_APP_PRODUCT_COMPARE_BASEURL,
      },
      product_request_form: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_PRODUCT_REQUEST_FORM_BASEURL ||
          process.env.GATSBY_APP_PRODUCT_REQUEST_FORM_BASEURL,
      },
      bim_request: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_BIM_REQUEST_BASEURL ||
          process.env.GATSBY_APP_BIM_REQUEST_BASEURL,
      },
      project_invite_confirm: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_PROJECT_INVITE_CONFIRM_BASEURL ||
          process.env.GATSBY_APP_PROJECT_INVITE_CONFIRM_BASEURL,
      },
      ft: {
        baseUrl:
          process.env.NEXT_PUBLIC_APP_FT_BASEURL ||
          process.env.GATSBY_APP_FT_BASEURL,
        sessionContinueCheckInterval:
          process.env.NEXT_PUBLIC_APP_FT_SESSION_CONTINUE_CHECK_INTERVAL ||
          process.env.GATSBY_APP_FT_SESSION_CONTINUE_CHECK_INTERVAL,
        sessionContinueResponseTimeout:
          process.env.NEXT_PUBLIC_APP_FT_SESSION_CONTINUE_RESPONSE_TIMEOUT ||
          process.env.GATSBY_APP_FT_SESSION_CONTINUE_RESPONSE_TIMEOUT,
        sessionUpdateInterval:
          process.env.NEXT_PUBLIC_APP_FT_SESSION_UPDATE_INTERVAL ||
          process.env.GATSBY_APP_FT_SESSION_UPDATE_INTERVAL,
      },
    },
    features: {
      projectExtProperties: parseJsonFormat(
        process.env.GATSBY_FEATURES_PROJECT_EXT_PROPERTIES ||
          process.env.NEXT_PUBLIC_FEATURES_PROJECT_EXT_PROPERTIES,
      ),
      projectFileStorage: parseJsonFormat(
        process.env.GATSBY_FEATURES_PROJECT_FILE_STORAGE ||
          process.env.NEXT_PUBLIC_FEATURES_PROJECT_FILE_STORAGE,
      ),
      projectNotification: parseJsonFormat(
        process.env.GATSBY_FEATURES_PROJECT_NOTIFICATION ||
          process.env.NEXT_PUBLIC_FEATURES_PROJECT_NOTIFICATION,
      ),
      projectLock: parseJsonFormat(
        process.env.GATSBY_FEATURES_PROJECT_LOCK ||
          process.env.NEXT_PUBLIC_FEATURES_PROJECT_LOCK,
      ),
      buildingCompletionPremium: parseJsonFormat(
        process.env.GATSBY_FEATURES_BUILDING_COMPLETION_PREMIUM ||
          process.env.NEXT_PUBLIC_FEATURES_BUILDING_COMPLETION_PREMIUM,
      ),
      ft: parseJsonFormat(
        process.env.GATSBY_FEATURES_FT || process.env.NEXT_PUBLIC_FEATURES_FT,
      ),
    },
    api: {
      auth: {
        username:
          process.env.NEXT_PUBLIC_API_AUTH_USERNAME ||
          process.env.GATSBY_API_AUTH_USERNAME,
        password:
          process.env.NEXT_PUBLIC_API_AUTH_PASSWORD ||
          process.env.GATSBY_API_AUTH_PASSWORD,
      },
      baseuri:
        process.env.NEXT_PUBLIC_API_BASEURI || process.env.GATSBY_API_BASEURI,
      timeout: parseJsonFormat(
        process.env.NEXT_PUBLIC_API_TIMEOUT || process.env.GATSBY_API_TIMEOUT,
      ),
    },
    services: {
      api: {
        endpoint:
          process.env.GATSBY_SERVICE_API_ENDPOINT ||
          process.env.NEXT_PUBLIC_SERVICE_API_ENDPOINT,
      },
      gateway: {
        baseuri:
          process.env.NEXT_PUBLIC_SERVICE_GATEWAY_URI ||
          process.env.GATSBY_SERVICE_GATEWAY_URI,
      },
      category: {
        baseuri:
          process.env.NEXT_PUBLIC_SERVICE_CATEGORY_BASEURI ||
          process.env.GATSBY_SERVICE_CATEGORY_BASEURI,
      },
      ppxt: {
        baseuri:
          process.env.NEXT_PUBLIC_SERVICE_PPXT_BASEURI ||
          process.env.GATSBY_SERVICE_PPXT_BASEURI,
        timeout: parseJsonFormat(
          process.env.NEXT_PUBLIC_SERVICE_PPXT_TIMEOUT ||
            process.env.GATSBY_SERVICE_PPXT_TIMEOUT,
        ),
      },
      reservation: {
        uri:
          process.env.NEXT_PUBLIC_SERVICE_RESERVATION_URI ||
          process.env.GATSBY_SERVICE_RESERVATION_URI,
      },
      project_product: {
        baseuri:
          process.env.NEXT_PUBLIC_SERVICE_PROJECT_PRODUCT_URI ||
          process.env.GATSBY_SERVICE_PROJECT_PRODUCT_URI,
      },
      project_image: {
        upload: {
          baseuri:
            process.env.NEXT_PUBLIC_SERVICE_PROJECT_IMAGE_UPLOAD_BASEURL ||
            process.env.GATSBY_SERVICE_PROJECT_IMAGE_UPLOAD_BASEURL,
        },
      },
      project_file: {
        upload: {
          uri:
            process.env.NEXT_PUBLIC_SERVICE_PROJECT_FILE_UPLOAD_URL ||
            process.env.GATSBY_SERVICE_PROJECT_FILE_UPLOAD_URL,
        },
      },
    },
    product: {
      image: {
        original: {
          baseurl:
            process.env.NEXT_PUBLIC_PRODUCT_ORIGINAL_IMAGE_BASEURL ||
            process.env.GATSBY_PRODUCT_ORIGINAL_IMAGE_BASEURL,
        },
        thumbnail: {
          baseurl:
            process.env.NEXT_PUBLIC_PRODUCT_THUMBNAIL_IMAGE_BASEURL ||
            process.env.GATSBY_PRODUCT_THUMBNAIL_IMAGE_BASEURL,
        },
      },
      ies: {
        baseurl:
          process.env.NEXT_PUBLIC_PRODUCT_IES_BASEURL ||
          process.env.GATSBY_PRODUCT_IES_BASEURL,
      },
      debug_icon_enabled: parseJsonFormat(
        process.env.NEXT_PUBLIC_PRODUCT_DEBUG_ICON_ENABLED ||
          process.env.GATSBY_PRODUCT_DEBUG_ICON_ENABLED,
      ),
    },
    site: {
      hostname,
      protocol,
      prefix,
      baseurl: `${protocol}://${hostname}${prefix}`,
    },
    board: {
      limitEntries:
        process.env.GATSBY_BOARD_LIMIT_ENTRIES ||
        process.env.NEXT_PUBLIC_BOARD_LIMIT_ENTRIES ||
        100,
    },
  })
}
