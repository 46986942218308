import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Links from '@arch-log/webapp.shared/Links'
import * as Literals from './Literals'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'

/**
 */
export const Message = withDefaultRenderComponent(
  ({ components: { Decorator = Styled.Message } = {} }) => (
    <Decorator>
      <Literals.Message />
    </Decorator>
  ),
)

/**
 */
export const CreateProjectLink = withDefaultRenderComponent(
  ({
    components: { Link = Links.Project.ToCreateProject } = {},
    redirectUri,
    ...props
  }) => {
    return (
      <Link
        className={styles.CreateProjectLink}
        routeProps={{
          redirectUri,
        }}
        {...props}
      >
        <Literals.CreateProjectLink />
      </Link>
    )
  },
)
