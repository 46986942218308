import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { Context } from '@arch-log/webapp.modules/user-profile/components/Scope/Entry/Context.jsx'

import * as Renderers from '@arch-log/webapp.modules/user-profile/components/PropertyRenderers'

/**
 */
export const FirstName = withContextValue(
  Context,
  ({ entry }) => entry.firstname,
)(Renderers.FirstName)

/**
 */
export const LastName = withContextValue(
  Context,
  ({ entry }) => entry.lastname,
)(Renderers.LastName)

/**
 */
export const PhoneticLastName = withContextValue(
  Context,
  ({ entry }) => entry.phoneticLastname,
)(Renderers.PhoneticLastName)

/**
 */
export const PhoneticFirstName = withContextValue(
  Context,
  ({ entry }) => entry.phoneticFirstname,
)(Renderers.PhoneticFirstName)
