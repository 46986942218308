import React from 'react'

import ArchLogIconWhiteImage from './archlog_icon_white.svg'
import ArchLogWhiteImage from './archlog_white.svg'
import ArchLogIconWhiteInProjectImage from './archlog_icon_white_project.svg'
import MarubeniArchlogRedImage from './marubeni-archlog_red.svg'

/**
 */
export const ArchLogWithIcon = (props) => (
  <img src={ArchLogIconWhiteImage} {...props} />
)

/**
 */
export const ArchLog = (props) => (
  <img src={ArchLogWhiteImage} alt="Arch-Log" {...props} />
)

/**
 */
export const MarubeniArchLog = (props) => (
  <img {...props} src={MarubeniArchlogRedImage} alt="Marubeni Arch-Log" />
)

/**
 */
export const ArchLogWithIconInProject = (props) => (
  <img src={ArchLogIconWhiteInProjectImage} alt="Arch-Log" {...props} />
)
