import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const GatewayError_Title = translated('common')(
  'Errors.GatewayError.Title',
)

/**
 */
export const GatewayError_Message = translated('common')(
  'Errors.GatewayError.Message',
)
