// extracted by mini-css-extract-plugin
export var Container = "styles-module--Container--ZAr47";
export var InProjectContainer = "styles-module--InProjectContainer--Vf1MC";
export var Languages = "styles-module--Languages--4eVE2";
export var Link = "styles-module--Link--G3QxI";
export var LinkToProject = "styles-module--LinkToProject--kB8DB";
export var LinkToSignIn = "styles-module--LinkToSignIn--IE1CO";
export var LinkToSignUp = "styles-module--LinkToSignUp--gO6dO";
export var LoginIcon = "styles-module--LoginIcon--w7dbX";
export var Logo = "styles-module--Logo--UWUcc";
export var LogoProject = "styles-module--LogoProject--s7lb6";
export var Logo_Image = "styles-module--Logo_Image--Jhg8z";
export var Menu = "styles-module--Menu--d0dQi";
export var Project = "styles-module--Project--KSAxH";
export var ProjectIcon = "styles-module--ProjectIcon--Fkjh3";
export var ProjectText = "styles-module--ProjectText--U013x";
export var Search = "styles-module--Search--eUnes";
export var SignIn = "styles-module--SignIn--6-iSc";
export var SignUp = "styles-module--SignUp--3lp+T";
export var Space = "styles-module--Space--G1OQx";