import * as config from '@arch-log/webapp.modules/config/config'

import { createRouteWithDefaultProps } from '../utils/RouteHelper'

/**
 * Generate CreateRoute function with DefaultProps
 */
const createRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.registration.baseUrl'),
}))

/**
 */
const createSearchParamsForRedirectUri = (redirectUri = null) => {
  const params = new URLSearchParams()

  if (redirectUri) {
    params.append('redirect_uri', redirectUri)
  }

  return params
}

/**
 */
export const toTop = createRoute(({ redirectUri = null, email = null}) => {
  const params = createSearchParamsForRedirectUri(redirectUri)

  if(email) {
    params.set('email', email)
  }

  return `/?${params.toString()}`
})
