import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import { useAuth } from '@arch-log/webapp.modules/auth.v2/components'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    enableSignIn = true,
    enableSignUp = true,
    showProjectLink = true,
    enableSearch = true,
    enableLogoLink = true,
    onSearch,
    defaultSearchKeyword,
    components: {
      Logo: logoComponents,
      Search: searchComponents,
      AuthenticatedMenu: authenticatedMenuComponents,
      Layout: {
        Container = Layout.Container,
        LogoSection = Layout.Logo,
        SearchSection = Layout.Search,
        SpaceSection = Layout.Space,
      } = {},
    } = {},
  }) => {
    return (
      <Container>
        <LogoSection>
          <Containers.Logo
            components={logoComponents}
            canNavigate={enableLogoLink}
          />
        </LogoSection>
        <SearchSection isEnabled={enableSearch}>
          <Containers.Search
            isEnabled={enableSearch}
            components={searchComponents}
            onSearch={onSearch}
            defaultKeyword={defaultSearchKeyword}
          />
        </SearchSection>
        <SpaceSection />
        <AuthenticatedMenu
          components={authenticatedMenuComponents}
          enableSignIn={enableSignIn}
          enableSignUp={enableSignUp}
          showProjectLink={showProjectLink}
        />
      </Container>
    )
  },
)

/**
 */
const AuthenticatedMenu = ({
  enableSignIn,
  enableSignUp,
  showProjectLink,
  components: {
    Menu: menuComponents,
    Layout: {
      ProjectSection = Layout.Project,
      SignUpSection = Layout.SignUp,
      SignInSection = Layout.SignIn,
      MenuSection = Layout.Menu,
    } = {},
  } = {},
}) => {
  const { loaded, isAuthenticated } = useAuth()

  if (!loaded) {
    return null
  }

  if (isAuthenticated) {
    return (
      <>
        <ProjectSection isVisible={showProjectLink}>
          <Containers.ToProject />
        </ProjectSection>
        <MenuSection>
          <Containers.Menu components={menuComponents} />
        </MenuSection>
      </>
    )
  }

  return (
    <>
      <SignUpSection isVisible={enableSignUp}>
        <Containers.SignUp />
      </SignUpSection>
      <SignInSection isVisible={enableSignIn}>
        <Containers.SignIn />
      </SignInSection>
      <MenuSection>
        <Containers.LogoutMenu components={menuComponents} />
      </MenuSection>
    </>
  )
}
