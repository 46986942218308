import React from 'react'

import * as BaseLayout from '@arch-log/webapp.shared/GlobalPageLayout.v2/Layout'
import * as BaseHeader from '@arch-log/webapp.shared/GlobalPageLayout.v2/Header'
import * as BaseFooter from '@arch-log/webapp.shared/GlobalPageLayout.v2/Footer'

/**
 */
export const Layout = ({ children }) => {
  return (
    <BaseLayout.Wrapper>
      <BaseLayout.Header>
        <BaseHeader.Default />
      </BaseLayout.Header>
      <BaseLayout.Body>{children}</BaseLayout.Body>
      <BaseLayout.Footer>
        <BaseFooter.Default />
      </BaseLayout.Footer>
    </BaseLayout.Wrapper>
  )
}
