import * as config from '@arch-log/webapp.modules/config/config'

import { createRouteWithDefaultProps } from '../utils/RouteHelper'

/**
 * Generate CreateRoute function with DefaultProps
 */
const createRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.product.baseUrl'),
}))

export const toDetail = createRoute(({ id }) => `/${id}/`)
