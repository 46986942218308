import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import { ChangeLanguage } from '@arch-log/webapp.shared/Language/Actions'

import * as I18n from '@arch-log/webapp.modules/i18n/components'
import * as Containers from './Containers'

/**
 */
export const Default = withDefaultRenderComponent(() => (
  <I18n.Languages.Active
    render={({ language }) => {
      switch (language) {
        case 'ja':
          return (
            <ChangeLanguage
              render={({ changeLanguage }) => (
                <Containers.ChangeLanguageEnglish
                  onClick={() => changeLanguage('en')}
                />
              )}
            />
          )
        case 'en':
          return (
            <ChangeLanguage
              render={({ changeLanguage }) => (
                <Containers.ChangeLanguageJapanese
                  onClick={() => changeLanguage('ja')}
                />
              )}
            />
          )
        default:
          return null
      }
    }}
  />
))
