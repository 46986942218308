import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const InProjectContainer = ({ children }) => (
  <div className={styles.InProjectContainer}>{children}</div>
)

/**
 */
export const Logo = ({ children }) => (
  <div className={styles.Logo}>{children}</div>
)

/**
 */
export const Menu = ({ children }) => (
  <div className={styles.Menu}>{children}</div>
)

/**
 */
export const LanguageNavi = ({ children }) => (
  <div className={styles.Languages}>{children}</div>
)

/**
 */
export const SignIn = ({ children, isVisible = true }) => {
  if (!isVisible) {
    return null
  }

  return <div className={styles.SignIn}>{children}</div>
}

/**
 */
export const SignUp = ({ children, isVisible = true }) => {
  if (!isVisible) {
    return null
  }

  return <div className={styles.SignUp}>{children}</div>
}

/**
 */
export const Search = ({ children, isEnabled = true }) => {
  if (!isEnabled) {
    return null
  }

  return <div className={styles.Search}>{children}</div>
}

/**
 */
export const Project = ({ children, isVisible = true }) => {
  if (!isVisible) {
    return null
  }

  return <div className={styles.Project}>{children}</div>
}

/**
 */
export const LogoProject = () => <p className={styles.LogoProject}>Project</p>

/**
 */
export const Space = () => <div className={styles.Space}></div>
