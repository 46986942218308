import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const Wrapper = ({ children }) => (
  <div className={styles.Wrapper}>{children}</div>
)

/**
 */
export const Row = ({ children }) => (
  <div className={styles.Row}>{children}</div>
)
