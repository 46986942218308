import React from "react"

import { init } from "./i18next"

import { Provider } from "./Provider"

/**
 */
const DefaultLoading = () => (
  <>Initializing Localization...</>
)

/**
 * Initialize
 */
export const Initializer = ({
  i18nextOptions,
  i18next: givenI18next,
  language,
  languages,
  children,
  loading: Loading = DefaultLoading,
  async,
}) => {
  const [i18next, setI18next] = React.useState(givenI18next)

  React.useLayoutEffect(() => {
    ;(async () => {
      if (!i18next) {
        const options = {
          ...(i18nextOptions ? i18nextOptions : {}),
          languages,
        }
        const i18next = await init(
          i18nextOptions ? i18nextOptions : {},
        )

        setI18next(i18next)
      }
    })()
  }, [])

  if (!async && !i18next) {
    return <Loading />
  }

  if (!i18next) {
    return null
  }

  return (
    <Provider
      i18next={i18next}
      language={language}
      languages={languages}
    >
      {children}
    </Provider>
  )
}

//
//export class Initializer extends React.Component {
//  static defaultProps = {
//    i18nextOptions: null,
//    i18next: null,
//  }
//
//  state = {
//    i18next: this.props.i18next,
//  }
//
//  componentDidMount = async () => {
//    if (!this.state.i18next) {
//      const options = {
//        ...(this.props.i18nextOptions
//          ? this.props.i18nextOptions
//          : {}),
//        languages: this.props.languages,
//      }
//      const i18next = await init(
//        this.props.i18nextOptions
//          ? this.props.i18nextOptions
//          : {},
//      )
//
//      this.setState({ ...this.state, i18next })
//    }
//  }
//
//  render = () => {
//    const {
//      language,
//      languages,
//      children,
//      loading: Loading = DefaultLoading,
//    } = this.props
//
//    if (!this.state.i18next) {
//      return <Loading />
//    }
//
//    return (
//      <Provider
//        i18next={this.state.i18next}
//        language={language}
//        languages={languages}
//      >
//        {children}
//      </Provider>
//    )
//  }
//}
//
