import React from 'react'

import * as Literals from './Literals'

import * as Layout from './Layout'

/**
 *
 */
export const NetworkError = ({
  components: {
    Wrapper = Layout.Wrapper,
    Title = Layout.Title,
    Message = Layout.Message,
  } = {},
}) => (
  <Wrapper>
    <Title>
      <Literals.NetworkError_Title />
    </Title>
    <Message>
      <Literals.NetworkError_Message />
    </Message>
  </Wrapper>
)
