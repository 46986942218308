import React from 'react'

import * as Links from './Links'
import * as Layout from './Layout'

/**
 */
export const Default = ({
  isVisible = true,
  components: {
    Layout: { Wrapper = Layout.Wrapper, Row = Layout.Row } = {},
  } = {},
}) => {
  if (!isVisible) {
    return null
  }

  return (
    <Wrapper>
      <Row>
        <Links.ToNews />
        <Links.ToPrSite />
        <Links.ToAbout />
        <Links.ToShowcase />
        <Links.ToHowTo />
        <Links.ToFaq />
        <Links.ToSystemRequirements />
        <Links.ToContact />
      </Row>
      <Row>
        <Links.ToPublish />
        <Links.ToPrivateAccount />
        <Links.ToReservation />
        <Links.ToProductRequestForm />
      </Row>
      <Row>
        <Links.ToCompanyProfile />
        <Links.ToTerms />
        <Links.ToCommercialTransactions />
        <Links.ToPrivacyPolicy />
      </Row>
    </Wrapper>
  )
}
