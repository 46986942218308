import React from 'react'

import * as DefaultStyles from './default.module.scss'

/**
 *
 */
const DefaultFormatter = (value, options = {}) => value

/**
 *
 */
export const LinkRenderer = ({
  value: linkTo,
  className,
  styles = DefaultStyles,
  format = DefaultFormatter,
  disableLink = false,
  children,
  ...props
}) => {
  return (
    <a
      href={disableLink ? '#' : linkTo}
      className={className ? className : styles.Link}
      {...props}
    >
      {format(children ?? linkTo, {
        linkTo,
        styles,
      })}
    </a>
  )
}
