import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

/**
 */
export const ToggleButton = ({ toggle, children }) => (
  <div className={styles.Navi_Trigger} onClick={() => toggle()}>
    {children}
  </div>
)

/**
 */
export const DropMenu = ({ children, ...props }) => (
  <div {...props}>{children}</div>
)

/**
 */
export const MenuTextContainer = ({ children }) => (
  <span className={styles.MenuText}>{children}</span>
)

/**
 */
export const Toggle = () => <Icons.Setting className={styles.Navi_Icon} />

/**
 */
export const LogoutIcon = () => (
  <Icons.Logout className={styles.MenuItem_Icon} />
)

/**
 */
export const ProjectIcon = () => (
  <Icons.Project className={styles.MenuItem_Icon} />
)

/**
 */
export const SettingIcon = () => (
  <Icons.AccountSetting className={styles.MenuItem_Icon} />
)

/**
 */
export const PluginDownloadIcon = () => (
  <Icons.Plugin className={styles.MenuItem_Icon} />
)

/**
 */
export const LanguageIcon = () => (
  <Icons.Language className={styles.MenuItem_Icon} />
)