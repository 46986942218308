import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const FirstName = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const LastName = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const PhoneticFirstName = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const PhoneticLastName = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}
