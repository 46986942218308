import React from 'react'

import envDefined from '@arch-log/webapp.macros/src/envDefined/macro'

// Use babel macros to switch by app
export const Link = require(envDefined(
  {
    GATSBY_IS_GATSBY: './gatsby/Link',
    NEXT_PUBLIC_IS_NEXT: './next/Link',
  },
  './Link',
)).Link
