import React from 'react'

import * as Module from '@arch-log/webapp.modules/auth.v2/components'
import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'
import * as Literals from './Literals'

/**
 */
const DefaultLoading = () => (
  <PageLoader>
    <Literals.Progress />
  </PageLoader>
)

/**
 */
export const Initializer = ({
  children,
  components: { Loading = DefaultLoading } = {},
  waitForComplete = false,
  throwGatewayError = true,
  throwNetWorkError = true,
  throwClientError = true,
  throwError = false,
}) => (
  <Module.FromSessionInitializer
    components={{ Loading: waitForComplete ? Loading : null }}
    throwGatewayError={throwGatewayError}
    throwNetWorkError={throwNetWorkError}
    throwClientError={throwClientError}
    throwError={throwError}
  >
    {children}
  </Module.FromSessionInitializer>
)
