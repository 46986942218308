import React from 'react'

import { MarubeniArchLog as LogoImage } from '@arch-log/webapp.shared/Logo/Image'
import { Copyright as BaseCopyright } from '@arch-log/webapp.shared/Copyright'

import * as BaseNavi from './Navi'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'

/**
 */
export const Navi = BaseNavi.Default

/**
 */
export const Logo = ({ components: { Decorator = Styled.Logo } = {} }) => {
  return (
    <Decorator>
      <LogoImage className={styles.Logo_Image} />
    </Decorator>
  )
}

/**
 */
export const Copyright = ({
  components: { Decorator = Styled.Copyright } = {},
}) => (
  <Decorator>
    <BaseCopyright />
  </Decorator>
)
