import React from 'react'

import IcoMoon from 'react-icomoon'

import * as styles from './styles.module.scss'

import './global.scss'

const iconSet = require('./selection.json')

/**
 *
 * <Icon name="xxx"/>
 * @param {*} param0
 */
export const Icon = ({ ...props }) => <IcoMoon iconSet={iconSet} {...props} />
