import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = ({
  showLinks = true,
  components: {
    Logo: logoComponents,
    Copyright: copyrightComponents,
    Navi: naviComponents,
    Layout: {
      Wrapper = Layout.Wrapper,
      LogoAndNaviContainer = Layout.LogoAndNaviContainer,
    } = {},
  } = {},
}) => {
  return (
    <Wrapper>
      <LogoAndNaviContainer>
        <Containers.Logo components={logoComponents} />
        <Containers.Navi components={naviComponents} isVisible={showLinks} />
      </LogoAndNaviContainer>
      <Containers.Copyright components={copyrightComponents} />
    </Wrapper>
  )
}
