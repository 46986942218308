import React from 'react'

import { Context } from './Context'

/**
 */
export const Initializer = ({ children }) => {
  const [isVisible, setVisible] = React.useState(false)

  const show = () => {
    setVisible(true)
  }

  const hide = () => {
    setVisible(false)
  }

  const toggle = () => {
    setVisible(!isVisible)
  }

  return (
    <Context.Provider value={{ isVisible, show, hide, toggle }}>
      {children}
    </Context.Provider>
  )
}
