/**
 * Application Based Links
 */
import React from 'react'

import { createLink } from '@arch-log/webapp.shared/Link'

import * as Routes from './Routes'

/**
 */
export const ToSearch = createLink(Routes.toSearch)

/**
 */
export const ToTop = ToSearch
