import React from 'react'

import * as styles from './styles.module.scss'

import dayjs from 'dayjs'

/**
 *
 */
export const Copyright = () => {
  const currentYear = dayjs().format('YYYY')

  return (
    <div className={styles.Copyright}>
      <div>&copy; 2019 - {currentYear} LOGLOG CO., Ltd.</div>
      <div>Licensed to Marubeni Arch - LOG Co., Ltd</div>
    </div>
  )
}
