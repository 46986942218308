import React from 'react'

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from as fromLinks,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import fetch from 'cross-fetch'
import * as config from '@arch-log/webapp.lib/config'
import { RetryLink } from '@apollo/client/link/retry'

import * as OnMemoryStorage from '@arch-log/webapp.modules/auth.v2/components/OnMemoryStorage'

export const useServiceApiClient = () => {
  const defaultEndpoint = config.get('services.api.endpoint')

  const createClient = ({
    uri = defaultEndpoint,
    linkOptions = {},
    cache = new InMemoryCache(),
    additionalLinks = [
      new RetryLink({
        delay: {
          initial: 100,
          max: 300,
          jitter: true,
        },
        attempts: {
          max: 3,
          //retryIf: ,
        },
      }),
    ],
    ...options
  } = {}) => {
    const httpLink = createHttpLink({
      uri,
      fetch,
      ...linkOptions,
    })

    const authLink = setContext(async (_, { headers }) => {
      const authorization = OnMemoryStorage.getInstance().getAuthorization()

      if (!authorization) {
        return { headers }
      }

      return {
        headers: {
          ...headers,
          authorization,
        },
      }
    })

    return new ApolloClient({
      link: fromLinks([authLink, ...additionalLinks, httpLink]),
      cache,
      ...options,
    })
  }

  return {
    createClient,
  }
}
