import React from 'react'

import * as Auth from '@arch-log/webapp.shared/Auth'
import * as SEO from 'components/SEO'

import { ServiceApiInitializer } from '@arch-log/webapp.modules/tools/ServiceApiInitializer.jsx'
import { Initializer as I18NInitializer } from 'modules/i18n/components/Initializer'
import { Initializer as ConfigInitializer } from '@arch-log/webapp.modules/config/components/Initializer'
import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'
import { Layout as DefaultPageLayout } from 'layouts/DefaultPageLayout'
import { Layout as TopPageLayout } from 'layouts/TopPageLayout'
import * as GoogleAnalytics from '@arch-log/webapp.shared/GoogleAnalytics'

const getPageLayout = (isTopPage) => {
  if (isTopPage) {
    return TopPageLayout
  } else {
    return DefaultPageLayout
  }
}

/**
 */
export const Container = ({ children, pageContext, ...props }) => {
  const PageLayout = getPageLayout(
    props.path === '/' || props.path.match(/\/(ja|en)\/$/),
  )

  const language = props?.params?.language ?? 'en'

  return (
    <ConfigInitializer>
      <SEO.Default lang={language} />
      <I18NInitializer
        language={language}
        loading={() => <PageLoader>Initialize Localizations</PageLoader>}
      >
        <Auth.Initializer>
          <ServiceApiInitializer>
            <PageLayout pageContext={pageContext} {...props}>
              {children}
            </PageLayout>
          </ServiceApiInitializer>
        </Auth.Initializer>
      </I18NInitializer>
    </ConfigInitializer>
  )
}

/**
 */
export default Container
