import React from 'react'

import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

/**
 */
export const Default = ({ title, description, lang = 'ja', keywords = [] }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titlePrefix,
    titleSuffix,
    description_ja,
    description_en,
    siteUrl,
    keywords: defaultKeywords,
  } = site.siteMetadata

  const defaultDescription = {
    ja: description_ja,
    en: description_en,
  }

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription?.[lang],
    url: `${siteUrl}${pathname}`,
    keywords: [...defaultKeywords, ...keywords].filter((v) => v).join(','),
  }

  return (
    <>
      <title>{`${titlePrefix}${seo.title}${titleSuffix}`}</title>
      <meta name="description" content={seo.description} />
      {seo.title && <meta property="og:title" content={seo.title} />}
      <meta name="keywords" content={seo.keywords} />
    </>
  )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titlePrefix
        titleSuffix
        description_ja
        description_en
        siteUrl
        keywords
      }
    }
  }
`
