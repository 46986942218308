/**
 * Application Based Links
 */
import React from 'react'

import { createLink } from '@arch-log/webapp.shared/Link'

import * as Routes from './Routes'

const ToFormRaw = createLink(Routes.toForm)

export const ToForm = ({ project, id, onClick, ...props }) => {
  const opener = (e) => {
    e.stopPropagation()
    e.preventDefault()

    window.open(Routes.toForm({ id, project }), '_blank')

    return false
  }
  return (
    <ToFormRaw
      {...props}
      project={project}
      id={id}
      onClick={onClick ?? opener}
    />
  )
}

/**
 */
export const ToTop = ToForm
