import React from 'react'

import { applyMiddleware, createStore, combineReducers } from 'redux'
import { Provider } from 'react-redux'

import createSagaMiddleware from 'redux-saga'
import { all, fork } from 'redux-saga/effects'

import { reducers as moduleReducers } from 'modules/reducers'
import { reducers as storeReducers } from '../reducers'

import { sagas as moduleSagas } from 'modules/sagas'
import { sagas as storeSagas } from '../sagas'

import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import { ReduxInitializer } from './ReduxInitializer'

// Combined Saga
function* sagas() {
  yield all([fork(moduleSagas), fork(storeSagas)])
}

// initialize store
const initStore = () => {
  const middleware = createSagaMiddleware()

  const reducer = combineReducers({
    ...moduleReducers(),
    ...storeReducers(),
  })

  const store = createStore(reducer, applyMiddleware(middleware))

  middleware.run(sagas)

  return store
}

/**
 */
export const Initializer = ({ children, usePersistor = false }) => {
  const store = initStore()

  return (
    <Provider store={store}>
      <ReduxInitializer>
        {usePersistor ? (
          <Persistor store={store}>{children}</Persistor>
        ) : (
          children
        )}
      </ReduxInitializer>
    </Provider>
  )
}

/**
 * Persistor if reducer is persistable
 */
const Persistor = ({ children, store }) => {
  const persistor = persistStore(store)

  return (
    <PersistGate loading={<span>Loading</span>} persistor={persistor}>
      {children}
    </PersistGate>
  )
}
