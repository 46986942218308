/**
 * Application Based Links
 */
import React from 'react'

import { createLink } from '@arch-log/webapp.shared/Link'
import * as config from '@arch-log/webapp.modules/config/config'

import * as Routes from './Routes'

const defaultProps = {
  baseUrl: config.get('apps.board.baseUrl'),
}

/**
 */
export const ToNew = createLink(Routes.toNew)

/**
 */
export const ToEdit = createLink(Routes.toEdit)
