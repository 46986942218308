import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  mutation UpdateMyProfileEntry($data: UpdateUserProfileEntry_Input_Data!) {
    result: updateMyProfileEntry(data: $data) {
      id
      firstname
      lastname
      phoneticFirstname
      phoneticLastname
      businessInfo {
        isIndividual
        companyLabel
        divisionLabel
        jobTitle
        businessType
      }
    }
  }
`
