import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Message = translated('common')('Errors.ProductNotSelected.Message')

/**
 */
export const SelectProducts = translated('common')('Errors.ProductNotSelected.SelectProducts')

