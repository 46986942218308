import React from 'react'

import * as Config from '../config'
import { Provider } from '@arch-log/webapp.atomics/components/Config'

/**
 * Dummy Wrapper Component
 */
export const Initializer = ({ children }) => {
  return <Provider config={Config.all()}>{children}</Provider>
}
