import React from 'react'

import { useAuth } from './useAuth'

/**
 */
export const OnReady = ({ children, components: { Loading = null } = {} }) => {
  const { loaded } = useAuth()

  if (!loaded) {
    if (Loading) {
      return <Loading />
    }
    return null
  }

  return <>{children}</>
}
