import React from 'react'

import { ExternalLink } from '@arch-log/webapp.shared/Link/ExternalLink'

/**
 */
export const ToNews = ({ children = 'News', ...props }) => (
  <ExternalLink
    to="https://blog.arch-log.com"
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    {children}
  </ExternalLink>
)

/**
 */
export const ToCompanyProfile = ({
  children = 'Company Profile',
  ...props
}) => (
  <ExternalLink
    to="https://m-arch-log.com/#Anchor02"
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    {children}
  </ExternalLink>
)

/**
 */
export const ToPrSite = ({ children = 'PrSite', ...props }) => (
  <ExternalLink to="https://pr.arch-log.com/" {...props}>
    {children}
  </ExternalLink>
)

/**
 */
export const ToMateria = ({ children = 'ArchMateria', ...props }) => (
  <ExternalLink
    to="https://arch-materia.com/"
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    {children}
  </ExternalLink>
)
