import { createActions } from 'redux-actions'
import { ACTION_PREFIX } from './consts'

/**
 */
export const {
  init: { request: init, success: initSuccess, failure: initFailure },
  changeLanguage: {
    request: changeLanguage,
    success: changeLanguageSuccess,
    failure: changeLanguageFailure,
  },
} = createActions(
  {
    INIT: {
      REQUEST: ({ language, languages, defaultLanguage, options = {} }) => ({
        language,
        languages,
        defaultLanguage,
        options,
      }),
      SUCCESS: ({ language }) => ({ language }),
      FAILURE: (error) => ({ error }),
    },
    CHANGE_LANGUAGE: {
      REQUEST: (language) => ({ language }),
      SUCCESS: (language) => ({ language }),
      FAILURE: (error) => ({ error }),
    },
  },
  {
    prefix: ACTION_PREFIX,
  },
)
