import React from 'react'

// Import Dependencies
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// Import redux components
import { init } from '../actions'
import { selectInit } from '../selectors'

/**
 * Initialize Redux
 */
export const ReduxInitializer = connect(
  // mapStateToProps,
  (state) => ({
    isInitialized: selectInit(state),
  }),
  // mapDispatchToProps,
  (dispatch) =>
    bindActionCreators(
      {
        init,
      },
      dispatch,
    ),
)(
  // BaseComponent
  class BaseInitializer extends React.Component {
    componentDidMount = () => {
      this.props.init()
    }

    render = () => {
      const {
        init,
        children,
        loader: Loader = () => <span>Initializing store...</span>,
      } = this.props

      return init ? <>{children}</> : <Loader />
    }
  },
)
