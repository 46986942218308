/**
 * Application Based Links
 */

import { createLink } from '@arch-log/webapp.shared/Link'

import * as Routes from './Routes'

/**
 */
export const ToTop = createLink(Routes.toTop)
