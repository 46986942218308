import { Router as BaseRouter } from '../utils/Router'

/**
 * 
 */
export class Router extends BaseRouter {
  static _instance

  static getInstance() {
    if (!Router._instance) {
      Router._instance = new Router()
    }

    return Router._instance
  }
}

