import React from 'react'

import * as UserProfile from '@arch-log/webapp.modules/user-profile/components'
import * as Auth from '@arch-log/webapp.modules/auth.v2/components'
import * as Links from '@arch-log/webapp.shared/Links'
import * as Visibility from './Visibility'
import * as LanguageNavi from '../LanguageNavi'
import * as Styled from './Styled'
import * as Literals from './Literals'

/**
 */
export const ChangeLanguage = LanguageNavi.Default

/**
 */
export const Initializer = ({ children }) => (
  <Visibility.Initializer>{children}</Visibility.Initializer>
)

/**
 */
export const ToPluginDownload = () => (
  <Links.ToPluginDownload>
    <Styled.PluginDownloadIcon />
    <Literals.PluginDownload />
  </Links.ToPluginDownload>
)

/**
 */
export const SignOut = () => (
  <Links.Auth.ToLogout>
    <Styled.LogoutIcon />
    <Literals.Logout />
  </Links.Auth.ToLogout>
)

/**
 */
export const ToProject = () => (
  <Links.Project.ToTop>
    <Styled.ProjectIcon />
    <Literals.ProjectManagement />
  </Links.Project.ToTop>
)

/**
 */
export const ToSetting = () => (
  <Links.ToAccountInfo>
    <Styled.SettingIcon />
    <Styled.MenuTextContainer>
      <Literals.AccountSetting />
      <Auth.OnReady>
        <UserProfile.Remote.Queries.MyEntry.Loader
          components={{ Loading: () => <>loading...</> }}
        >
          (<UserProfile.Scope.Entry.Properties.LastName />
          <UserProfile.Scope.Entry.Properties.FirstName />)
        </UserProfile.Remote.Queries.MyEntry.Loader>
      </Auth.OnReady>
    </Styled.MenuTextContainer>
  </Links.ToAccountInfo>
)

/**
 */
export const Toggle = ({
  components: { Button = Styled.ToggleButton } = {},
}) => {
  const { toggle } = React.useContext(Visibility.Context)

  return (
    <Button toggle={toggle}>
      <Styled.Toggle />
    </Button>
  )
}

/**
 */
export const DropMenu = ({
  components: { Decorator = Styled.DropMenu } = {},
  children,
}) => {
  const { isVisible, hide } = React.useContext(Visibility.Context)

  if (!isVisible) {
    return null
  }

  return <Decorator onMouseLeave={() => hide()}>{children}</Decorator>
}
