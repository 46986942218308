import React from 'react'

import * as Layout from './Layout'
import * as Literals from './Literals'

/**
 *
 */
export const PageNotFound = ({
  components: {
    Container = Layout.Container,
    Title = Layout.Title,
    Message = Layout.Message,
  } = {},
}) => (
  <Container>
    <Title>
      <Literals.PageNotFound_Title />
    </Title>
    <Message>
      <Literals.PageNotFound_Message />
    </Message>
  </Container>
)
