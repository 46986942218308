import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ className, children }) => (
  <div className={className ?? styles.Container}>{children}</div>
)

/**
 */
export const Title = ({ className, children }) => (
  <h1 className={className ?? styles.Title}>{children}</h1>
)

/**
 */
export const Message = ({ className, children }) => (
  <span className={className ?? styles.Message}>{children}</span>
)

export const Navigation = ({ className, children }) => (
  <div className={className ?? styles.Navigation}>{children}</div>
)
