import React from 'react'

import * as Links from '@arch-log/webapp.shared/Links'
import * as Literals from './Literals'

/**
 */
export const ToNews = () => {
  const { formatLink } = Literals.useLiterals()

  return <Links.ToNews>{formatLink('News')}</Links.ToNews>
}

/**
 */
export const ToAbout = () => {
  const { formatLink } = Literals.useLiterals()

  return <Links.ToAbout>{formatLink('About')}</Links.ToAbout>
}

/**
 */
export const ToPrSite = () => {
  const { formatLink } = Literals.useLiterals()

  return (
    <Links.Externals.ToPrSite>{formatLink('PrSite')}</Links.Externals.ToPrSite>
  )
}

/**
 */
export const ToShowcase = () => {
  const { formatLink } = Literals.useLiterals()

  return <Links.ToShowcase>{formatLink('Showcase')}</Links.ToShowcase>
}

/**
 */
export const ToHowTo = () => {
  const { formatLink } = Literals.useLiterals()

  return <Links.ToHowTo>{formatLink('HowTo')}</Links.ToHowTo>
}

/**
 */
export const ToFaq = () => {
  const { formatLink } = Literals.useLiterals()

  return <Links.ToFaq>{formatLink('Faq')}</Links.ToFaq>
}

/**
 */
export const ToSystemRequirements = () => {
  const { formatLink } = Literals.useLiterals()

  return (
    <Links.ToSystemRequirements>
      {formatLink('SystemRequirements')}
    </Links.ToSystemRequirements>
  )
}

/**
 */
export const ToContact = () => {
  const { formatLink } = Literals.useLiterals()

  return <Links.ToContact>{formatLink('Contact')}</Links.ToContact>
}

/**
 */
export const ToCampaigns = () => {
  const { formatLink } = Literals.useLiterals()

  return <Links.ToCampaigns>{formatLink('Campaigns')}</Links.ToCampaigns>
}

/**
 */
export const ToPublish = () => {
  const { formatLink } = Literals.useLiterals()

  return <Links.ToPublish>{formatLink('Publish')}</Links.ToPublish>
}

/**
 */
export const ToPrivateAccount = () => {
  const { formatLink } = Literals.useLiterals()

  return (
    <Links.ToPrivateAccount>
      {formatLink('PrivateAccount')}
    </Links.ToPrivateAccount>
  )
}

/**
 */
export const ToReservation = () => {
  const { formatLink } = Literals.useLiterals()

  return (
    <Links.ToReservationIntro>
      {formatLink('Reservation')}
    </Links.ToReservationIntro>
  )
}

/**
 */
export const ToCompanyProfile = () => {
  const { formatLink } = Literals.useLiterals()

  return (
    <Links.ToCompanyProfile>
      {formatLink('CompanyProfile')}
    </Links.ToCompanyProfile>
  )
}

/**
 */
export const ToTerms = () => {
  const { formatLink } = Literals.useLiterals()

  return <Links.ToTerms>{formatLink('Terms')}</Links.ToTerms>
}

/**
 */
export const ToCommercialTransactions = () => {
  const { formatLink } = Literals.useLiterals()

  return (
    <Links.ToCommercialTransactions>
      {formatLink('CommercialTransactions')}
    </Links.ToCommercialTransactions>
  )
}

/**
 */
export const ToPrivacyPolicy = () => {
  const { formatLink } = Literals.useLiterals()

  return (
    <Links.ToPrivacyPolicy>{formatLink('PrivacyPolicy')}</Links.ToPrivacyPolicy>
  )
}

/**
 */
export const ToProductRequestForm = () => {
  const { formatLink } = Literals.useLiterals()

  return (
    <Links.ToProductRequestForm>
      {formatLink('ProductRequestForm')}
    </Links.ToProductRequestForm>
  )
}
