import {
  withContextProps,
  withContextValue,
} from '@arch-log/webapp.modules/utils/withContext'
import { Context } from '@arch-log/webapp.modules/user-profile/components/Scope/Entry/Context.jsx'

import * as Renderers from '@arch-log/webapp.modules/user-profile/components/PropertyRenderers'

/**
 */
export const BusinessType = withContextProps(
  Context,
  ({ entry, businessTypes }) => ({
    value: entry.businessInfo?.businessType,
    labels: businessTypes,
  }),
)(Renderers.BusinessInfo.BusinessType)

/**
 */
export const JobTitle = withContextValue(
  Context,
  ({ entry }) => entry.businessInfo?.jobTitle,
)(Renderers.BusinessInfo.JobTitle)

/**
 */
export const DivisionLabel = withContextValue(
  Context,
  ({ entry }) => entry.businessInfo?.divisionLabel,
)(Renderers.BusinessInfo.DivisionLabel)

/**
 */
export const CompanyLabel = withContextValue(
  Context,
  ({ entry }) => entry.businessInfo?.companyLabel,
)(Renderers.BusinessInfo.CompanyLabel)
