import * as config from '@arch-log/webapp.modules/config/config'

import { createRouteWithDefaultProps } from '../utils/RouteHelper'

/**
 * Generate CreateRoute function with DefaultProps
 */
const createRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.contact.baseUrl'),
}))

/**
 */
export const toContact = createRoute(({ subject }) => {
  if (subject) {
    return `/?subject=${subject}`
  }
  return '/'
})

export const toTop = toContact
