exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-commercial-transaction-index-jsx": () => import("./../../../src/pages/commercialTransaction/index.jsx" /* webpackChunkName: "component---src-pages-commercial-transaction-index-jsx" */),
  "component---src-pages-howto-index-jsx": () => import("./../../../src/pages/howto/index.jsx" /* webpackChunkName: "component---src-pages-howto-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-locale-language-about-index-jsx": () => import("./../../../src/pages/{Locale.language}/about/index.jsx" /* webpackChunkName: "component---src-pages-locale-language-about-index-jsx" */),
  "component---src-pages-locale-language-commercial-transaction-index-jsx": () => import("./../../../src/pages/{Locale.language}/commercialTransaction/index.jsx" /* webpackChunkName: "component---src-pages-locale-language-commercial-transaction-index-jsx" */),
  "component---src-pages-locale-language-howto-index-jsx": () => import("./../../../src/pages/{Locale.language}/howto/index.jsx" /* webpackChunkName: "component---src-pages-locale-language-howto-index-jsx" */),
  "component---src-pages-locale-language-index-jsx": () => import("./../../../src/pages/{Locale.language}/index.jsx" /* webpackChunkName: "component---src-pages-locale-language-index-jsx" */),
  "component---src-pages-locale-language-merit-index-jsx": () => import("./../../../src/pages/{Locale.language}/merit/index.jsx" /* webpackChunkName: "component---src-pages-locale-language-merit-index-jsx" */),
  "component---src-pages-locale-language-on-demand-index-jsx": () => import("./../../../src/pages/{Locale.language}/onDemand/index.jsx" /* webpackChunkName: "component---src-pages-locale-language-on-demand-index-jsx" */),
  "component---src-pages-locale-language-privacy-policy-index-jsx": () => import("./../../../src/pages/{Locale.language}/privacyPolicy/index.jsx" /* webpackChunkName: "component---src-pages-locale-language-privacy-policy-index-jsx" */),
  "component---src-pages-locale-language-private-account-index-jsx": () => import("./../../../src/pages/{Locale.language}/privateAccount/index.jsx" /* webpackChunkName: "component---src-pages-locale-language-private-account-index-jsx" */),
  "component---src-pages-locale-language-publish-index-jsx": () => import("./../../../src/pages/{Locale.language}/publish/index.jsx" /* webpackChunkName: "component---src-pages-locale-language-publish-index-jsx" */),
  "component---src-pages-locale-language-terms-index-jsx": () => import("./../../../src/pages/{Locale.language}/terms/index.jsx" /* webpackChunkName: "component---src-pages-locale-language-terms-index-jsx" */),
  "component---src-pages-merit-index-jsx": () => import("./../../../src/pages/merit/index.jsx" /* webpackChunkName: "component---src-pages-merit-index-jsx" */),
  "component---src-pages-on-demand-index-jsx": () => import("./../../../src/pages/onDemand/index.jsx" /* webpackChunkName: "component---src-pages-on-demand-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacyPolicy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-private-account-index-jsx": () => import("./../../../src/pages/privateAccount/index.jsx" /* webpackChunkName: "component---src-pages-private-account-index-jsx" */),
  "component---src-pages-publish-index-jsx": () => import("./../../../src/pages/publish/index.jsx" /* webpackChunkName: "component---src-pages-publish-index-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */)
}

