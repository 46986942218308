/**
 * Application Based Links
 */
import React from 'react'

import { createLink } from '@arch-log/webapp.shared/Link'

import * as Routes from './Routes'

/**
 */
export const ToLogin = createLink(Routes.toLogin)
  

/**
 */
export const ToLogout = createLink(Routes.toLogout)

/**
 */
export const ToResetPassword = createLink(Routes.toResetPassword)
