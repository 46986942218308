import * as config from '@arch-log/webapp.modules/config/config'

import { createRouteWithDefaultProps } from '../utils/RouteHelper'

/**
 * Generate CreateRoute function with DefaultProps
 */
const createRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.project.baseUrl'),
}))

const createFtRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.ft.baseUrl'),
}))

/**
 */
const createSearchParamsForRedirectUri = (redirectUri = null) => {
  const params = new URLSearchParams()

  if (redirectUri) {
    params.append('redirect_uri', redirectUri)
  }

  return params
}

/**
 */
export const toProjectList = createRoute(({ refetch }) => {
  if (refetch) {
    return `/?refetch=true`
  }

  return '/'
})

/**
 */
export const toCreateProject = createRoute(({ redirectUri = null }) => {
  const params = createSearchParamsForRedirectUri(redirectUri)

  return `/new/?${params.toString()}`
})

/**
 */
export const toEditInfo = createRoute(({ project }) => `/${project}/edit/`)

/**
 */
export const toProducts = createRoute(({ project, projectUuid }) => {
  if(project) {
    return `/${project}/products/`
  }
  else if(projectUuid) {
    return `/products/?uuid=${projectUuid}`
  }

})

/**
 */
export const toSampleRequests = createRoute(
  ({ project }) => `/${project}/requests/`,
)

/**
 */
export const toRequestHistory = createRoute(
  ({ project }) => `/${project}/request/history/`,
)

/**
 */
export const toMaterialBoards = createRoute(
  ({ project }) => `/${project}/boards/`,
)

/**
 */
export const toAddressList = createRoute(
  ({ project }) => `/${project}/addresses/`,
)

/**
 */
export const toAddressNew = createRoute(
  ({ project }) => `/${project}/address/new/`,
)

/**
 */
export const toMemberList = createRoute(({ project }) => `/${project}/members/`)

/**
 */
export const toInviteMember = createRoute(
  ({ project }) => `/${project}/invite/`,
)

/**
 */
export const toTagManage = createRoute(({ project }) => `/${project}/tags/`)

/**
 */
export const toSetting = createRoute(({ project }) => `/${project}/setting/`)

/**
 */
export const toProjectTop = toProducts

/**
 */
export const toTop = toProjectList

/**
 */
export const toFt = createFtRoute(({ project }) => `/${project}/tables`)

/**
 */
export const toFileStorage = createRoute(({ project }) => `/${project}/files`)

/**
 */
export const toNotificationGroups = createRoute(
  ({ project }) => `/${project}/notification/groups`,
)
