import React from 'react'

import * as Literals from './Literals'

import * as Layout from './Layout'
import * as styles from './styles.module.scss'

/**
 *
 */
export const Default = ({
  components: {
    Wrapper = Layout.Container,
    Title = Layout.Title,
    Message = Layout.Message,
  } = {},
}) => (
  <Wrapper className={styles.Wrapper}>
    <Title>
      <Literals.DefaultError_Title />
    </Title>
    <Message>
      <Literals.DefaultError_Message />
    </Message>
  </Wrapper>
)
