/**
 */
class OnMemoryStorage {
  constructor({ authorization = null } = {}) {
    if (OnMemoryStorage._instance) {
      return OnMemoryStorage._instance
    }

    this.authorization = authorization

    OnMemoryStorage._instance = this
  }

  /**
   */
  setAuthorization = (authorization) => {
    this.authorization = authorization
  }

  /**
   */
  getAuthorization = () => {
    return this.authorization
  }
}

/**
 */
export const getInstance = () => {
  return new OnMemoryStorage()
}

/**
 */
export const initAuthorization = (token) =>
  getInstance().setAuthorization(token ? `Bearer ${token}` : null)
