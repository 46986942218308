import { all, takeLeading, put, call, select } from 'redux-saga/effects'

import * as actions from './actions'

import { log } from './utils'

//import { initI18Next } from './i18n'
import * as selector from './selectors'
import moment from 'moment'

/**
 */
function* handleInit(action) {
  try {
    log(action)

    //const i18next = yield call(initI18Next, {
    //  ...action.payload.options,
    //  fallbackLng: action.payload.defaultLanguage,
    //  supportedLngs: action.payload.languages,
    //  language: action.payload.language,
    //})

    //yield call(moment.locale, i18next.language)
    yield call(moment.locale, action.payload.language)

    yield put(actions.initSuccess({ langauge: action.payload.language }))
  } catch (e) {
    console.error(e)
    yield put(actions.initFailure(e))
  }
}

/**
 */
function* handleChangeLanguage(action) {
  try {
    log(action)

    //const i18next = yield select(selector.selectI18Next)

    // Plugin will update the language by provider so ignore
    //const ret = yield call(
    //  [i18next, i18next.changeLanguage],
    //  action.payload.language,
    //)

    yield call(moment.locale, action.payload.language)

    yield put(actions.changeLanguageSuccess(action.payload.language))
  } catch (e) {
    console.error(e)
    yield put(actions.changeLanguageFailure(e))
  }
}

/**
 */
export function* sagas() {
  yield all([
    takeLeading('MODULE/I18N/INIT/REQUEST', handleInit),
    takeLeading('MODULE/I18N/CHANGE_LANGUAGE/REQUEST', handleChangeLanguage),
  ])
}
