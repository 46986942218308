/**
 * Application Based Links
 */
import React from 'react'

import { createLink } from '@arch-log/webapp.shared/Link'
import * as config from '@arch-log/webapp.modules/config/config'

import * as Routes from './Routes'

const defaultProps = {
  baseUrl: config.get('apps.plugin.baseUrl'),
}

/**
 */
export const ToProjects = createLink(Routes.toProjects)

/**
 */
export const ToProject = createLink(Routes.toProject)

export const ToTop = ToProjects
