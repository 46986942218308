import { handleActions } from 'redux-actions'

import { ACTION_PREFIX } from './consts'

export const initState = {
  init: false,
}

/**
 * Root reducer
 */
export const reducers = () => ({
  store: handleActions(
    {
      INIT: {
        SUCCESS: (state, action) => ({
          ...state,
          init: true,
        }),
      },
    },
    initState,
    {
      prefix: ACTION_PREFIX,
    },
  ),
})
