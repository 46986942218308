import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const Wrapper = ({ children }) => (
  <footer className={styles.Wrapper}>{children}</footer>
)

/**
 */
export const LogoAndNaviContainer = ({ children }) => (
  <div className={styles.LogoAndNaviContainer}>{children}</div>
)
