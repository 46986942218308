import React from 'react'

import { Link as GatsbyLink } from 'gatsby'
import { Context as I18NContext } from '@arch-log/webapp.modules/i18n/components/Languages/Context'

import { ExternalLink } from '../ExternalLink'

import * as config from '@arch-log/webapp.modules/config/config'

/**
 * @prop to
 * @prop language
 */
export const Link = ({
  to,
  baseUrl = '',
  target,
  language = null,
  onClick,
  condition = null,
  ...props
}) => {
  //  const { language: selected } = React.useContext(I18NContext)
  //  const lng = language ?? selected
  //
  if (target) {
    return (
      <ExternalLink
        baseUrl={
          to.startsWith('http') || (baseUrl && baseUrl.length !== 0)
            ? baseUrl
            : `${config.get('site.baseurl')}/`
        }
        to={to}
        target={target}
        {...props}
        onClick={(e) => {
          e.stopPropagation()
          if (onClick) {
            onClick(e)
          }
        }}
      />
    )
  }

  //if (`${config.get('site.baseurl')}/` === baseUrl) {
  if (!to.startsWith('http')) {
    //    const internalPath = ['/', lng, to].join('')

    const {
      className,
      children,
      state,
      activeClassName,
      activeStyle,
      partiallyActive,
      replace,
    } = props

    return (
      <GatsbyLink
        to={to}
        onClick={(e) => {
          e.stopPropagation()
          if (onClick) {
            onClick(e)
          }
        }}
        className={className}
        state={state}
        activeClassName={activeClassName}
        activeStyle={activeStyle}
        partiallyActive={partiallyActive}
        replace={replace}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <ExternalLink
      baseUrl={baseUrl}
      to={to}
      {...props}
      language={language}
      target={target}
      onClick={(e) => e.stopPropagation()}
    />
  )
}
