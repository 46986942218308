import React from 'react'

import { Icon } from '@arch-log/webapp.atomics/components/Icon'
import * as MaterialIcons from '@mui/icons-material'

import * as DefaultStyles from './styles.module.scss'
//import * as Images from "./images"

export * from './Source/index.jsx'

/**
 */
export const Check = (props) => <Icon icon="check" {...props} />

/**
 */
export const CheckBox_Checked = (props) => <Icon icon="check" {...props} />

/**
 */
export const CheckBox_Checked_Fill = (props) => (
  <Icon icon="check-box-fill" {...props} />
)

/**
 */
export const CheckBox_Unchecked = (props) => (
  <Icon icon="check-box-outline_blank" {...props} />
)

/**
 */
export const CheckBoxAll_Checked = (props) => (
  <Icon icon="check-box-library-add-fill" {...props} />
)

/**
 */
export const CheckBoxAll_Indeterminate = (props) => (
  <Icon icon="check-box-indeterminate_fill" {...props} />
)

/**
 */
export const CheckBoxAll_Unchecked = (props) => (
  <Icon icon="check-box-outline_blank" {...props} />
)

/**
 */
export const CheckCircle = (props) => <Icon icon="check" {...props} />

/**
 */
export const CheckCircleSelected = (props) => <Icon icon="check" {...props} />

/**
 */
export const Search = (props) => <Icon icon="filter" {...props} />
/**
 */
export const Filter = (props) => <Icon icon="filter-list-alt" {...props} />

/**
 */
export const CheckItem = (props) => (
  <Icon icon="file_download_done" {...props} />
)

/**
 */
export const Sort = (props) => <Icon icon="sort" {...props} />

/**
 */
export const SortAsc = (props) => <Icon icon="expand-less" {...props} />

/**
 */
export const SortDesc = (props) => <Icon icon="expand-more" {...props} />

/**
 */
export const Download = (props) => <Icon icon="download" {...props} />

/**
 */
export const ToggleDown = (props) => <Icon icon="triangle" {...props} />

/**
 */
export const Dropdown = (props) => <Icon icon="triangle" {...props} />

/**
 */
export const ModalClose = (props) => <Icon icon="cross" {...props} />

/**
 */
export const Menu = (props) => <Icon icon="menu" {...props} />

/**
 */
export const NaviBack = (props) => <Icon icon="circle-arrow-left" {...props} />

/**
 */
export const Visibility = (props) => <Icon icon="bulb-gear" {...props} />

/**
 */
export const Link = (props) => <Icon icon="link" {...props} />

/**
 */
export const Setting = (props) => <Icon icon="gear" {...props} />
/**
 */
export const ExpandMore = (props) => <Icon icon="expand-more" {...props} />

/**
 */
export const ExpandLess = (props) => <Icon icon="expand-less" {...props} />

/**
 */
export const AddedProducts = (props) => <Icon icon="view-module" {...props} />

/**
 */
export const ToFt = (props) => <Icon icon="file-text" {...props} />

/**
 */
export const SampleRequests = (props) => <Icon icon="fact_check" {...props} />

/**
 */
export const More = (props) => <Icon icon="keyboard_control" {...props} />
/**
 */
export const MaterialBoards = (props) => <Icon icon="dashboard" {...props} />

/**
 */
export const Info = (props) => <Icon icon="info_outline" {...props} />

/**
 */
export const Addresses = (props) => <Icon icon="location_on" {...props} />

/**
 */
export const Members = (props) => <Icon icon="people_alt" {...props} />

/**
 */
export const CreateNew = (props) => <Icon icon="circle-plus" {...props} />

/**
 */
export const Close = (props) => <Icon icon="cross" {...props} />

/**
 */
export const Delete = (props) => <Icon icon="delete-fill" {...props} />

/**
 */
export const ToList = (props) => <Icon icon="list_alt" {...props} />

/**
 */
export const Logout = (props) => <Icon icon="logout" {...props} />

/**
 */
export const Login = (props) => <Icon icon="login" {...props} />

/**
 */
export const Project = (props) => <Icon icon="list_alt" {...props} />

/**
 */
export const ProjectSetting = (props) => <Icon icon="build" {...props} />

/**
 */
export const AccountSetting = (props) => <Icon icon="person" {...props} />

/**
 */
export const Plugin = (props) => <Icon icon="extension" {...props} />

/**
 */
export const HowToLink = (props) => <Icon icon="arrow-right" {...props} />

/**
 */
export const Case1 = (props) => <Icon icon="cases-1" {...props} />

/**
 */
export const Case2 = (props) => <Icon icon="cases-2" {...props} />

/**
 */
export const Case3 = (props) => <Icon icon="cases-3" {...props} />

/**
 */
export const Case4 = (props) => <Icon icon="cases-4" {...props} />

/**
 */
export const Language = (props) => <Icon icon="language" {...props} />

/**
 */
export const Tags = (props) => <Icon icon="local_offer" {...props} />

/**
 */
export const ProductOption = (props) => <Icon icon="layer" {...props} />

/**
 */
export const ToAddToProduct = (props) => <Icon icon="circle-plus" {...props} />

/**
 */
export const Compare = (props) => <Icon icon="cases-2" {...props} />

/**
 */
export const NaviBefore = (props) => <Icon icon="navigate-before" {...props} />

/**
 */
export const DispatchBulkAction = (props) => (
  <Icon icon="playlist-add-check" {...props} />
)

/**
 */
export const BIM = (props) => <Icon icon="cases-4" {...props} />

/**
 */
export const Circle = (props) => <Icon icon="circle" {...props} />

/**
 */
export const Square = (props) => <Icon icon="square" {...props} />

/**
 */
export const Triangl = (props) => <Icon icon="triangl" {...props} />

/**
 */
export const CircleOutline = (props) => (
  <Icon icon="circle_outline" {...props} />
)

/**
 */
export const SquareOutline = (props) => (
  <Icon icon="square_outline" {...props} />
)

/**
 */
export const TriangleOutline = (props) => (
  <Icon icon="triangle_outline" {...props} />
)

/**
 */
export const CrossOutline = (props) => <Icon icon="cross_outline" {...props} />

/**
 */
export const FolderOpenIcon = (props) => <MaterialIcons.FolderOpen {...props} />

/**
 */
export const DeleteIcon = (props) => <MaterialIcons.Delete {...props} />

/**
 */
export const FileDownloadIcon = (props) => (
  <MaterialIcons.FileDownloadDone {...props} />
)

/**
 */
export const NotificationGroups = (props) => {
  return <MaterialIcons.Groups {...props} />
}

/**
 */
export const EnableLockSystem = (props) => {
  return <MaterialIcons.LockOutlined {...props} />
}
