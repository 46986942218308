/**
 * Application Based Links
 */
import React from 'react'

import { createLink } from '@arch-log/webapp.shared/Link'

import * as Routes from './Routes'

/**
 */
export const ToProjectList = createLink(Routes.toProjectList)

/**
 */
export const ToCreateProject = createLink(Routes.toCreateProject)

/**
 */
export const ToEditInfo = createLink(Routes.toEditInfo)

/**
 */
export const ToProducts = createLink(Routes.toProducts)

/**
 */
export const ToSampleRequests = createLink(Routes.toSampleRequests)

/**
 */
export const ToRequestHistory = createLink(Routes.toRequestHistory)

/**
 */
export const ToMaterialBoards = createLink(Routes.toMaterialBoards)

/**
 */
export const ToAddressList = createLink(Routes.toAddressList)

/**
 */
export const ToAddressNew = createLink(Routes.toAddressNew)

/**
 */
export const ToMemberList = createLink(Routes.toMemberList)

/**
 */
export const ToInviteMember = createLink(Routes.toInviteMember)

/**
 */
export const ToTagManage = createLink(Routes.toTagManage)

/**
 */
export const ToSetting = createLink(Routes.toSetting)

/**
 */
export const ToProjectTop = ToProducts

/**
 */
export const ToTop = ToProjectList

/**
 */
export const ToFt = createLink(Routes.toFt)

/**
 */
export const ToFileStorage = createLink(Routes.toFileStorage)

/**
 */
export const ToNotificationGroups = createLink(Routes.toNotificationGroups)
