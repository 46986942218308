import * as config from '@arch-log/webapp.modules/config/config'

/**
 * Append tailing slash if needed
 */
export const withSlash = (path) => {
  if (path) {
    if (path.startsWith('/')) {
      return path
    }
    return `/${path}`
  } else {
    return ''
  }
}

/**
 * Append tailing slash if needed
 */
export const withoutSlash = (path) => {
  if (path) {
    if (path.startsWith('/')) {
      return path.substr(1) 
    }
    return `${path}`
  } else {
    return ''
  }
}

export const createRoute =
  (genRelativePathFn, defaultProps = () => ({})) =>
  (props = {}) => {
    const mergedProps = { ...defaultProps(), ...props }

    const { baseUrl = null, language = null, useFQDN = false } = mergedProps

    if(!useFQDN) {
      if (!baseUrl || (baseUrl === `${config.get('site.baseurl')}/`)) {
        // return relative path
        // RelativePath does not include language on its subpart
        return `${withSlash(language)}${
          withSlash(genRelativePathFn(mergedProps))
        }`
      }
    }

    return `${baseUrl}${language ?? ''}${
      language
        ? withSlash(genRelativePathFn(mergedProps))
        : withoutSlash(genRelativePathFn(mergedProps))
    }`
  }

export const createRouteWithDefaultProps =
  (defaultProps) => (genRelativePathFn) =>
    createRoute(genRelativePathFn, defaultProps)
