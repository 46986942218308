import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const SignUp = translated('module.auth')('SignUp')

/**
 */
export const SignIn = translated('module.auth')('SignIn')

/**
 */
export const Project = translated('common')('Menu.ToProject')
