// extracted by mini-css-extract-plugin
export var Item_Container = "styles-module--Item_Container--UFVMW";
export var Items_Container = "styles-module--Items_Container--01Qc4";
export var LinkToRegistration = "styles-module--LinkToRegistration--Il94+";
export var MenuItem_Icon = "styles-module--MenuItem_Icon--474h5";
export var MenuText = "styles-module--MenuText--omerq";
export var Navi_Icon = "styles-module--Navi_Icon--IYXUT";
export var Navi_Item = "styles-module--Navi_Item--ah5cD";
export var Navi_Items = "styles-module--Navi_Items--DDbts";
export var Navi_Trigger = "styles-module--Navi_Trigger--0Z6Pb";
export var Navi_Wrapper = "styles-module--Navi_Wrapper--tJUI8";