import * as config from '@arch-log/webapp.modules/config/config'

import { createRouteWithDefaultProps } from '../utils/RouteHelper'

import * as Project from '@arch-log/webapp.shared/AppDefinitions/Project/Routes'

/**
 * Generate CreateRoute function with DefaultProps
 */
const createRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.board.baseUrl'),
}))

export const toList = Project.toMaterialBoards

/**
 */
export const toNew = createRoute(
  ({ project = null }) => `/new/?pid=${project}`
)

/**
 */
export const toEdit = createRoute(
  ({ id = null }) => `/edit/${id}`
)
