import React from 'react'

import { Link } from './Link'
import { Context as I18NContext } from '@arch-log/webapp.modules/i18n/components/Languages/Context'

/**
 */
export const createLink = (propsToPath, defaultProps) =>
  /**
   * WrappedLink
   */
  function WrappedLink({ language = null, routeProps = {}, ...props }) {
    const { language: selected } = React.useContext(I18NContext)
    const passProps = {
      ...defaultProps,
      ...props,
      language: language ?? selected,
    }

    return (
      <Link to={propsToPath({ ...passProps, ...routeProps })} {...passProps} />
    )
  }
