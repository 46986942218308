import React from 'react'

import * as Styled from './Styled'
import * as Literals from './Literals'

/**
 */
export const ChangeLanguageJapanese = ({
  components: { Decorator = Styled.ChangeLaunguage } = {},
  onClick,
}) => {
  return (
    <Decorator onClick={onClick}>
      <Styled.LanguageIcon />
      <Literals.ChangeToJapanese />
    </Decorator>
  )
}

/**
 */
export const ChangeLanguageEnglish = ({
  components: { Decorator = Styled.ChangeLaunguage } = {},
  onClick,
}) => {
  return (
    <Decorator onClick={onClick}>
      <Styled.LanguageIcon />
      <Literals.ChangeToEnglish />
    </Decorator>
  )
}
