import * as config from '@arch-log/webapp.modules/config/config'

import { createRouteWithDefaultProps } from '../utils/RouteHelper'

/**
 * Generate CreateRoute function with DefaultProps
 */
const createRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.project_product.baseUrl'),
}))

/**
 */
export const toForm = createRoute(({ id = [], project = null }) => {
  const params = new URLSearchParams()
    
  ;[id].flat().forEach((id) => {
    params.append('id', id)
  })

  if (project) {
    params.set('pid', project)
    params.set('force', true)
  }

  return `/?${params}`
})

/**
 */
export const toTop = toForm
