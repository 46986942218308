import * as AddToProject    from '@arch-log/webapp.shared/AppDefinitions/AddToProject/Routes'
import * as Auth            from '@arch-log/webapp.shared/AppDefinitions/Auth/Routes'
import * as Board           from '@arch-log/webapp.shared/AppDefinitions/Board/Routes'
import * as BimRequest      from '@arch-log/webapp.shared/AppDefinitions/BimRequest/Routes'
import * as Contact         from '@arch-log/webapp.shared/AppDefinitions/Contact/Routes'
import * as Faq             from '@arch-log/webapp.shared/AppDefinitions/Faq/Routes'
import * as HowTo           from '@arch-log/webapp.shared/AppDefinitions/HowTo/Routes'
import * as Plugin          from '@arch-log/webapp.shared/AppDefinitions/Plugin/Routes'
import * as PluginDownload  from '@arch-log/webapp.shared/AppDefinitions/PluginDownload/Routes'
import * as Product         from '@arch-log/webapp.shared/AppDefinitions/Product/Routes'
import * as ProductSearch   from '@arch-log/webapp.shared/AppDefinitions/ProductSearch/Routes'
import * as Project         from '@arch-log/webapp.shared/AppDefinitions/Project/Routes'
import * as Registration    from '@arch-log/webapp.shared/AppDefinitions/Registration/Routes'
import * as Requirements    from '@arch-log/webapp.shared/AppDefinitions/Requirements/Routes'
import * as Reservation     from '@arch-log/webapp.shared/AppDefinitions/Reservation/Routes'
import * as Setting         from '@arch-log/webapp.shared/AppDefinitions/Setting/Routes'
import * as Showcase        from '@arch-log/webapp.shared/AppDefinitions/Showcase/Routes'
import * as Static          from '@arch-log/webapp.shared/AppDefinitions/Static/Routes'

/**
 *
 */
export const toLogin = Auth.toLogin

/**
 *
 */
export const toLogout = Auth.toLogout

/**
 */
export const toProductSearch = ProductSearch.toSearch

/**
 */
export const toProductDetail = Product.toDetail

/**
 */
export const toAddProduct = AddToProject.toForm

/**
 */
export const toHowTo = Static.toHowTo
