import * as config from '@arch-log/webapp.modules/config/config'

import { createRouteWithDefaultProps } from '../utils/RouteHelper'

/**
 * Generate CreateRoute function with DefaultProps
 */
const createRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.bim_request.baseUrl'),
}))

/**
 */
export const toTop = createRoute(({ ids = [], project = null }) => {
  const qs = new URLSearchParams()

  ;[ids].flat().forEach((id) => {
    qs.append('id', id)
  })

  if (project) {
    qs.append('pid', project)
  }

  return `/?${qs}`
})
