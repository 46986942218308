/**
 * Application Based Links
 */
import React from 'react'

import { createLink } from '@arch-log/webapp.shared/Link'

import * as Routes from './Routes'

/**
 */
export const ToAccountInfo = createLink(Routes.toAccountInfo)

/**
 */
export const ToAccountEdit = createLink(Routes.toAccountEdit)

/**
 */
export const ToAddressEdit = createLink(Routes.toAddressEdit)

/**
 */
export const ToChangeEmail = createLink(Routes.toChangeEmail)

/**
 */
export const ToChangePassword = createLink(Routes.toChangePassword)

/**
 */
export const ToRenderingService = createLink(Routes.toRenderingService)
