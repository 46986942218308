// @flow
import { handleActions } from 'redux-actions'

import { ACTION_PREFIX } from './consts'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

/**
 */
export const initState = {
  isInitialized: false,
  i18next: null,
  language: null,
}

/**
 * Root reducer
 */
export const reducers = () => ({
  i18n: persistReducer(
    {
      key: 'i18n',
      storage,
      whitelist: ['language'],
    },
    handleActions(
      {
        INIT: {
          SUCCESS: (state, action) => ({
            ...state,
            isInitialized: true,
            //i18next: action.payload.i18next,
            language: action.payload.language,
          }),
        },
        CHANGE_LANGUAGE: {
          SUCCESS: (state, action) => ({
            ...state,
            language: action.payload.language,
          }),
        },
      },
      initState,
      {
        prefix: ACTION_PREFIX,
      },
    ),
  ),
})
