import * as config from '@arch-log/webapp.modules/config/config'
import { conditionToQueryParams } from '@arch-log/webapp.modules/product/utils/ConditionHelper'

import { createRouteWithDefaultProps } from '../utils/RouteHelper'

import { Router } from './Router'

/**
 * Generate CreateRoute function with DefaultProps
 */
const createRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.product_search.baseUrl'),
}))

/**
 */
export const toSearch = createRoute(
  ({ condition = {}, project = null, compare: givenCompare = null } = {}) => {
    const queryParams = new URLSearchParams(conditionToQueryParams(condition))

    if ((project ?? null) !== null) {
      queryParams.set('pid', project)
    }

    const compare = [givenCompare].flat().filter((v) => v)

    if (compare.length > 0) {
      queryParams.set('compare', compare.join(','))
    }

    return Router.getInstance().generatePath('/', queryParams)
  },
)

export const toTop = toSearch
