import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const CompanyLabel = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const JobTitle = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const BusinessType = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  labels = [],
  language = 'ja',
  ...props
}) => {
  const label = labels.find((v) => v.key === value)

  return (
    <Renderer
      value={label ? label.labels[language] : defaultValue}
      {...props}
    />
  )
}

/**
 */
export const DivisionLabel = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}
