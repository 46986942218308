import * as config from '@arch-log/webapp.modules/config/config'

import { createRouteWithDefaultProps } from '../utils/RouteHelper'

/**
 * Generate CreateRoute function with DefaultProps
 */
const createRoute = createRouteWithDefaultProps(() => ({
  baseUrl: config.get('apps.setting.baseUrl'),
}))

/**
 */
export const toAccountInfo = createRoute(() => '/account/')

/**
 */
export const toAccountEdit = createRoute(() => '/account/edit/')

/**
 */
export const toAddressEdit = createRoute(() => '/address/edit/')

/**
 */
export const toChangeEmail = createRoute(() => `/changeEmail/`)

/**
 */
export const toChangePassword = createRoute(() => `/changePassword/`)

/**
 */
export const toRenderingService = createRoute(() => `/rendering/`)
