/**
 * React tag to load translations of i18next
 */
import React from "react"

//import { Context } from "./Context"

import { I18nContext } from "react-i18next"
import { log } from "./utils"

/**
 * Because of gatsby restriction, Plugin cannot run StaticQuery.
 * Therefore, this plugin only provider the logic to import translations into i18next from result nodes
 *
 * ```
 * <StaticQuery
 *  ...
 *  render={ ({ allLocale }) => (
 *    <ImportTranslationNodes
 *      nodes={allLocale.nodes}
 *      { ...props }
 *    >
 *  )}
 * />
 */
export const ImportTranslations = ({
  translations,
  children,
}) => {
  const { i18n } = React.useContext(I18nContext)

  const [isReady, setReady] = React.useState(false)

  // Effect only when language changed
  React.useLayoutEffect(() => {
    translations
      //.filter((node) => node.language === i18n.language)
      .forEach((node) => {
        // add only if resource is not existed
        if (
          !i18n.hasResourceBundle(
            node.language,
            node.namespace,
          )
        ) {
          i18n.addResourceBundle(
            node.language,
            node.namespace,
            JSON.parse(node.internal.content),
            true,
            true,
          )

          log(
            "add ResourceBundle: ",
            node.language,
            node.namespace,
          )
        }
      })

    setReady(true)
  }, [i18n.language])

  if (!isReady) {
    return null
  }

  return <>{children}</>
}
