import React from 'react'

/**
 */
export const LanguageContext = React.createContext({
  language: null,
  isActive: null,
})

/**
 *
 */
export const LanguageProvider = ({ language, isActive = null, children }) => (
  <LanguageContext.Provider value={{ language, isActive }}>
    {children}
  </LanguageContext.Provider>
)

/**
 */
export const Language = ({
  format = (v) => v,
  isActive,
  render: Renderer = ({ children }) => <>{children}</>,
}) => {
  const { language } = React.useContext(LanguageContext)

  return (
    <Renderer
      value={language}
      language={language}
      isActive={isActive}
      format={format}
    >
      {format(language)}
    </Renderer>
  )
}
