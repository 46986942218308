import React from 'react'

import * as DefaultStyles from './default.module.scss'

const DefaultFormatter = (value) => value ?? ''

/**
 *
 */
export const TextRenderer = (props) => {
  const {
    id,
    name,
    style,
    value,
    children,
    className,
    formatter = DefaultFormatter,
    prefix,
    suffix,
    styles = DefaultStyles,
    empty = null,
  } = props

  if ((value ?? null) === null) {
    return <>{empty}</>
  }

  return (
    <span
      className={className ? className : styles.Text}
      style={style}
      name={name}
      id={id}
    >
      {prefix}
      {formatter(value ?? children ?? '', {
        ...props,
        styles,
        className,
      })}
      {suffix}
    </span>
  )
}
