import React from 'react'

import { LanguageProvider } from '../Language'

import { Context } from './Context'
/**
 * With selected language scope
 */
export const Active = ({
  render: Renderer = ({ children }) => <>{children}</>,
  children,
}) => {
  const { language } = React.useContext(Context)

  const passProps = {
    language,
    isActive: true,
  }

  return (
    <LanguageProvider {...passProps}>
      <Renderer {...passProps}>{children}</Renderer>
    </LanguageProvider>
  )
}
