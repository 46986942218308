/**
 */
import createLogger from 'debug'
export const log = createLogger('module:i18n')

/**
 */
export const resolveContentPath = (p) => {
  return p.replace(/^\/[a-z]{2}\//, '/')
}
