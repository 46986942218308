import React from 'react'

import * as styles from './styles.module.scss'

import * as Routes from '@arch-log/webapp.shared/Routes'

const Context = React.createContext()

/**
 */
const DefaultOnSearch = (keyword) => {
  window.location.href = Routes.toProductSearch({ keyword }).href
}

/**
 */
export const Initializer = ({
  children,
  onSearch = DefaultOnSearch,
  defaultKeyword = '',
}) => {
  const [keyword, setKeyword] = React.useState(defaultKeyword)

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSearch(keyword)
        return false
      }}
    >
      <Context.Provider value={{ keyword, setKeyword }}>
        {children}
      </Context.Provider>
    </form>
  )
}

/**
 */
export const Keyword = ({ className, defaultValue, ...props }) => {
  const { keyword, setKeyword } = React.useContext(Context)

  React.useEffect(() => {
    if(defaultValue) {
      setKeyword(defaultValue)
    }
  }, [])

  return (
    <input
      {...props}
      type="text"
      value={keyword}
      className={className ?? styles.Keyword}
      onChange={(e) => {
        setKeyword(e.target.value)
      }}
    />
  )
}

/**
 */
export const Submit = ({ children, className, ...props }) => {
  return (
    <button
      {...props}
      type="submit"
      className={className ?? styles.SubmitButton}
    >
      {children}
    </button>
  )
}
