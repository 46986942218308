import React from 'react'

import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 */
export const Default = ({
  message,
  components: {
    Message: messageComponents,
    Layout: { Wrapper = Layout.Wrapper, Body = Layout.Body } = {},
  } = {},
}) => {
  return (
    <Wrapper>
      <Body>
        <Containers.Message components={messageComponents} message={message} />
      </Body>
    </Wrapper>
  )
}
