import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const NetworkError_Title = translated('common')(
  'Errors.NetworkError.Title',
)

/**
 */
export const NetworkError_Message = translated('common')(
  'Errors.NetworkError.Message',
)
