/**
 * Application Based Links
 */
import React from 'react'

import { createLink } from '@arch-log/webapp.shared/Link'

import * as Routes from './Routes'


/**
 */
export const ToInfo = createLink(Routes.toInfo)

/**
 */
export const ToTop = ToInfo

/**
 */
export const ToSchedule = createLink(Routes.toSchedule)

/**
 */
export const ToRequest = createLink(Routes.toRequest)
