import React from 'react'

import { Context } from './Context'

export const useAuth = () => {
  const context = React.useContext(Context) ?? {}
  const isAuthorized = Boolean(context?.authorization)

  return {
    ...context,
    uid: context?.uid,
    isAuthenticated: isAuthorized,
  }
}
