import { all, put, takeLatest } from 'redux-saga/effects'

import * as actions from './actions'

/**
 */
import { log } from './util'

/**
 */
function* handleInit(action) {
  try {
    log(action)
    // Do Initialization here
  } catch (e) {
    console.error(e)
    yield put(actions.initFailure(e))
  }
}

/**
 */
export function* sagas() {
  yield all([takeLatest('STORE/INIT/REQUEST', handleInit)])
}
