import React from 'react'

import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import * as config from '@arch-log/webapp.modules/config/config'
import { Context } from '@arch-log/webapp.modules/i18n/components/Languages/Context'

/**
 */
export const ChangeLanguage = ({
  render: Renderer,
  options = null,
  ...props
}) => {
  const [path, setPath] = React.useState('/')

  const { language } = React.useContext(Context)

  const location = useLocation()

  return (
    <Renderer
      changeLanguage={(lang) => {
        navigate(
          location.href
            .replace(config.get('site.baseurl'), '')
            .replace(new RegExp(`/${language}(\/|$)?`), `/${lang}/`),
        )
      }}
    />
  )
}
//BaseChangeLanguage
