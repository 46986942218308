import React from 'react'

import { Initializer as PluginInitializer } from '@arch-log/gatsby-plugin-i18n/Initializer'
import { Initializer as ModuleInitializer } from '@arch-log/webapp.modules/i18n/components'
import { Translations } from './Translations'
import { StaticQuery, graphql } from 'gatsby'

/**
 * Inject Locale configuration from config
 */
export const Initializer = ({ language, children, ...props }) => (
  <StaticQuery query={query}>
    {({
      site: {
        siteMetadata: { localization },
      },
    }) => (
      <ModuleInitializer languages={localization.languages} language={language}>
        <PluginInitializer
          language={language}
          languages={localization.languages}
          defaultLanguage={localization.defaultLanguage}
          {...props}
        >
          <Translations>{children}</Translations>
        </PluginInitializer>
      </ModuleInitializer>
    )}
  </StaticQuery>
)

const query = graphql`
  query {
    site {
      siteMetadata {
        localization {
          languages
          defaultLanguage
        }
      }
    }
  }
`
