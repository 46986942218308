import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Message = translated('common')('Errors.NotAuthenticated.Message')

/**
 */
export const Login = translated('module.auth')('Login')
