import React from 'react'

import merge from 'deepmerge'

export const withCustomComponents = (Component, customComponents) =>
  function CustomComponent({ components = {}, ...props }) {
    return (
      <Component components={merge(customComponents, components)} {...props} />
    )
  }

/**
 */
export const withCustomDefaultProps = (Component, defaultProps = {}) =>
  function CustomComponent(props) {
    return <Component {...merge(defaultProps, props)} />
  }
