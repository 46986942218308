import { Direction } from '@arch-log/webapp.lib/order'
import { OrderBy } from '@arch-log/webapp.modules/product/order'

import { csvsplit } from '@arch-log/webapp.lib/StringHelper'

export const queryParamsToCondition = (params) => {
  const searchParams = new URLSearchParams(params)

  return {
    keyword: searchParams.getAll('key'),
    category: searchParams.get('cat'), 
    manufacturer: searchParams.get('man'),
    onlyBimSupported: JSON.parse(searchParams.get('bim')) ?? false,
    direction: searchParams.get('d') ?? Direction.Desc,
    orderBy: searchParams.get('o') ?? OrderBy.Popularity,
    includeChildProducts: JSON.parse(searchParams.get('ec')) ?? false,
    parentProductCode: searchParams.get('ppc'),
  }
}

export const conditionToQueryParams = (condition) => {
  const params = new URLSearchParams()

  //  // set keyword
  //  params.set(
  //    'key',
  //    [
  //      ...new Set([
  //        ...[condition.keyword ?? []].flat(),
  //        ...[condition.parentProductCode]
  //          .flat()
  //          .filter((v) => v)
  //          .map((v) => `parent:${v}`),
  //      ]),
  //    ].join(' '),
  //  )

  Object.entries(condition).map(([k, v]) => {
    if ((v ?? null) !== null) {
      switch (k) {
        case 'keyword':
          [v].flat().forEach((value) => {
            params.append('key', value)
          })
          break
        case 'parentProductCode':
          params.set('ppc', v)
          break
        case 'category':
          params.set('cat', v)
          break
        case 'manufacturer':
          params.set('man', v)
          break
        case 'onlyBimSupported':
          params.set('bim', v)
          break
        case 'direction':
          params.set('d', v)
          break
        case 'orderBy':
          params.set('o', v)
          break
        case 'includeChildProducts':
          params.set('ec', v)
          break
        default:
          break
      }
    }
  })

  return params
  //
  //  return Object.fromEntries(
  //    Object.entries({
  //      key: [
  //        ...new Set([
  //          ...[condition.keyword ?? []].flat(),
  //          ...[condition.parentProductCode]
  //            .flat()
  //            .filter((v) => v)
  //            .map((v) => `parent:${v}`),
  //        ]),
  //      ].join(' '),
  //      cat: condition.category,
  //      man: condition.manufacurer,
  //      bim: condition.onlyBimSupported,
  //      d: condition.direction,
  //      o: condition.orderBy,
  //      ec: condition.includeChildProducts,
  //      //ppc: condition.parentProductCode,
  //    }).filter(([k, v]) => v),
  //  )
}

export const keywordToCondition = (keyword) => {
  const condition = csvsplit(keyword, ' ').reduce(
    (cum, cur) => {
      if (cur.startsWith('parent:')) {
        return {
          ...cum,
          parentProductCode: [
            ...cum.parentProductCode,
            cur.substr('parent:'.length),
          ],
        }
      } else {
        return {
          ...cum,
          keyword: [...cum.keyword, cur],
        }
      }
    },
    { keyword: [], parentProductCode: [] },
  )
  return {
    ...condition,
    includeChildProducts: (condition.parentProductCode.length > 0)
  }
}

export const conditionToKeyword = (condition) => {
  const { keyword = [], parentProductCode = null } = condition ?? {}

  const ret = [
    [keyword]
      .flat()
      .filter((v) => v)
      .map((v) => quote(v)),
    [parentProductCode]
      .flat()
      .filter((v) => v)
      .map((v) => `parent:${quote(v)}`),
  ]
    .flat()
    .filter((v) => v)
    .join(' ')

  return ret
}

const quote = (v) => {
  if (v && v.includes(' ')) {
    return `"${v}"`
  }
  return v
}

export const extractDomainFromAccount = (account) => {
  if(account) {
    return account.split('@')?.[1]
  }

  return null
}
