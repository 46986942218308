import { useTranslation } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('common')

  const formatLink = (key, ...options) => {
    return t(`Footer.Link.${key}`, ...options)
  }

  return {
    formatLink,
  }
}
