/**
 * React tag to load translations of i18next
 */
import React from 'react'

import { graphql, StaticQuery } from 'gatsby'

import { ImportTranslations } from '@arch-log/gatsby-plugin-i18n/ImportTranslations'

/**
 */
export const Translations = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allLocale {
            nodes {
              internal {
                content
              }
              namespace
              language
            }
          }
        }
      `}
      render={({ allLocale }) => (
        <ImportTranslations translations={allLocale.nodes}>
          {children}
        </ImportTranslations>
      )}
    />
  )
}
