import React from 'react'

import { Context } from './Context'

/**
 * This only provide current active language which provided by page context.
 *
 */
export const Initializer = ({ children, language, languages }) => {
  return (
    <Context.Provider value={{ language, languages }}>
      {children}
    </Context.Provider>
  )
}
