import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const Wrapper = ({ children }) => (
  <div className={styles.Wrapper}>{children}</div>
)

/**
 */
export const Header = ({ children }) => (
  <header className={styles.Header}>{children}</header>
)

/**
 */
export const Body = ({ children }) => (
  <div className={styles.Body}>{children}</div>
)

/**
 */
export const Footer = ({ children }) => (
  <footer className={styles.Footer}>{children}</footer>
)

/**
 */
export const InViewWrapper = ({ children }) => (
  <div className={styles.InView}>{children}</div>
)

/**
 */
export const OutViewWrapper = ({ children }) => (
  <div className={styles.OutView}>{children}</div>
)
