import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const ChangeToJapanese = translated('common')(
  'GlobalHeader.ChangeToJapanese',
)

/**
 */
export const ChangeToEnglish = translated('common')(
  'GlobalHeader.ChangeToEnglish',
)
