import React from 'react'

//import * as URI from 'uri-js'
//import * as URIJoin from 'url-join'

const dotSlash = (path) => {
  if (path.startsWith('/')) {
    return `.${path}`
  }
  return path
}

const createUrl = (to, baseUrl = null) => {
  if (baseUrl) {
    return `${baseUrl}${dotSlash(to)}`
  } else {
    return to
  }
}
/**
 *
 */
export const ExternalLink = ({
  baseUrl,
  language = null,
  children,
  to,
  activeClassName,
  ...props
}) => {
  try {
    //const toTargetUrl = (baseUrl ? `${baseUrl}${to}` : to).replace(/\/\//g, '/')
    //const toTargetUrl = (baseUrl ? `${baseUrl}${to}` : to)

    //const url = new URL(toTargetUrl)
    const url = createUrl(to ?? '/', baseUrl)

    const params = new URLSearchParams()
    if (language) {
      //url.searchParams.append('lang', language)
      params.append('lang', language)
    }

    return (
      <a
        href={`${urlWithQueryParams(url, params)}`}
        hrefLang={language}
        {...props}
      >
        {children}
      </a>
    )
  } catch (e) {
    return (
      <a href="#" {...props}>
        {children}
      </a>
    )
  }
}

const urlWithQueryParams = (url, params) => {
  if (url.indexOf('?') > 0) {
    return `${url}&${params}`
  }
  return `${url}?${params}`
}
