import React from 'react'

import { query } from './query.js'
import { Context } from './Context.jsx'
import { useQuery } from '@apollo/client'
import { Provider } from '@arch-log/webapp.modules/user-profile/components/Scope/Entry/Provider.jsx'

/**
 */
export const Loader = ({
  components: { Loading = null } = {},
  children,
  ...props
}) => {
  const { data, loading, ...more } = useQuery(query)

  const entry = React.useMemo(() => {
    return data?.entry
  }, [data?.entry])

  const businessTypes = React.useMemo(() => {
    return data?.businessTypes
  }, [data?.businessTypes])

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        ...more,
        data,
        loading,
        entry,
        businessTypes,
      }}
    >
      <Provider entry={entry} businessTypes={businessTypes} {...props}>
        {children}
      </Provider>
    </Context.Provider>
  )
}
