import React from 'react'

import { ApolloProvider } from '@apollo/client'
import { useServiceApiClient } from './useServiceApiClient.js'

/**
 */
export const ServiceApiInitializer = ({
  children,
  client: givenClient = null,
}) => {
  const { createClient } = useServiceApiClient()

  const client = React.useMemo(() => {
    return givenClient ?? createClient()
  }, [givenClient])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
