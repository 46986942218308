import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Wrapper = ({ children, ...props }) => {
  return (
    <div className={styles.Navi_Wrapper} {...props}>
      {children}
    </div>
  )
}

/**
 */
export const Dropdown = ({ children }) => (
  <div className={styles.Navi_Items}>{children}</div>
)

/**
 */
export const Items = ({ children }) => (
  <ul className={styles.Items_Container}>{children}</ul>
)

/**
 */
export const Item = ({ children }) => (
  <li className={styles.Item_Container}>{children}</li>
)
